import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convert } from 'html-to-text';

import { BsPencilSquare, BsFire } from 'react-icons/bs';
import { GrMultiple } from "react-icons/gr";
import { LuKey } from 'react-icons/lu';

import { setStats } from '../../../store/reducers/statsReducer';
import TemplateLink from '../../../components/template';
import { NumberFormatter } from '../../../helpers/number';

import './overview.scss';

export default function Overview() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const websites = useSelector(state => state.websites);
    const articleStats = useSelector(state => state.stats);
    const articles = useSelector(state => state.articles).data;
    const templateTitles = useSelector(state => state.template);
    const articleStatus = useSelector(state => state.articles).status;

    const [articlesLoading, setArticlesLoading] = useState(true);

    useEffect(() => {
        if (articleStats?.status === "pending") {
            setArticlesLoading(true);

            // Fetch article stats
            fetch(process.env.REACT_APP_API + "/article/get-stats", {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                }
            })
            .then(data => data.json())
            .then(data => {
                data["status"] = "fetched";
                dispatch(setStats(data));
                setArticlesLoading(false);
            });
        }
        else setArticlesLoading(false);
    }, [articleStats.status]);

    return (
        <div id="overview">
            {/* OVERALL */}
            <div className="overall">
                <div className="box">
                    { articlesLoading ? (
                        <img src="/images/loader.svg" className="loader" alt="Loader" />
                    ) : (
                        <>
                            <h5>Total Articles</h5>
                            <p className="value">
                                <span>{articleStats.totalArticles}</span>
                            </p>
                        </>
                    )}
                </div>
                <div className="box">
                    { articlesLoading ? (
                        <img src="/images/loader.svg" className="loader" alt="Loader" />
                    ) : (
                        <>
                            <h5>Total Websites</h5>
                            <p className="value">
                                <span>{websites.length}</span>
                            </p>
                        </>
                    )}
                </div>
                <div className="box">
                    { articlesLoading ? (
                        <img src="/images/loader.svg" className="loader" alt="Loader" />
                    ) : (
                        <>
                            <h5>Published Articles</h5>
                            <p className="value">
                                <span>{articleStats.publishedArticles}</span>
                            </p>
                        </>
                    )}
                </div>
                <div className="box">
                    { articlesLoading ? (
                        <img src="/images/loader.svg" className="loader" alt="Loader" />
                    ) : (
                        <>
                            <h5>Generated Images</h5>
                            <p className="value">
                                <span>{articleStats.generatedImages}</span>
                            </p>
                        </>
                    )}
                </div>
                <div className="box">
                    { articlesLoading ? (
                        <img src="/images/loader.svg" className="loader" alt="Loader" />
                    ) : (
                        <>
                            <h5>Consumed Credits</h5>
                            <p className="value">
                                <span style={
                                    articleStats.consumedCredits.toString().length > 4 
                                        ? articleStats.consumedCredits.toString().length > 5
                                            ? { fontSize: "45px" }
                                            : { fontSize: "55px" }
                                        : undefined
                                    }
                                >
                                    {NumberFormatter(articleStats.consumedCredits)}
                                </span>
                            </p>
                        </>
                    )}
                </div>
            </div>

            {/* ARTICLES */}
            <div className="articles">
                <h3>Last Generated Articles</h3>

                <div className="container">
                    { articles.length > 0 ? (
                        <>
                        { articles.slice(0, 3).map((a, i) => {
                            if (!a?.id) return <></>;
                            let maxLen = 180;
                            let overviewContent = convert(a.content.slice(0, maxLen) + (a.content.length > maxLen ? "..." : ""));

                            return (
                                <div className="article" key={i}>
                                    <Link to={"/dashboard/articles/?id=" + a.id}>
                                        <div className="title">
                                            <h3>{templateTitles[a.type]}</h3>

                                            <div className="keywords">
                                                {a.keywords.split(", ").map((keyword, j) => {
                                                    if (!keyword) return <></>
                                                    return (
                                                        <div key={"k-" + j}>
                                                            <span>{keyword}</span>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className="content">
                                            <p>{overviewContent}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                        </>
                    ) : (
                        <>
                        {articleStatus === "fetched" && (
                            <p>There are no articles, <Link to="/dashboard/templates">generate now</Link>.</p>
                        )}
                        {articleStatus === "pending" && (
                            <img src="/images/loader.svg" className="loader" alt="Loader" />
                        )}
                        </>
                    )}
                </div>
            </div>

            {/* WEBSITES */}
            <div className="sites">
                <h3>Connected Websites</h3>

                <div className="container">
                    { websites.length > 0 ? (
                        <>
                        { websites.slice(0, 5).map((site, i) => {
                            return (
                                <div className="site" key={i}>
                                    <a href={site.url} target="_blank" rel="noreferrer">
                                        <img src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${site.url}&size=256` || "/images/default-website.png"} />
                                        <h4>{site.url}</h4>
                                    </a>
                                </div>
                            )
                        })}
                        </>
                    ) : (
                        <p>There are no connected websites, <Link to="/dashboard/websites">connect now</Link>.</p>
                    )}
                </div>

                { websites.length > 5 && (
                    <p className="more">... and {websites.length - 5} more</p>
                )}
            </div>

            {/* TEMPLATES */}
            <div className="templates">
                <h3>Most Popular Templates</h3>
                <div className="container">
                    <TemplateLink click={() => navigate("/dashboard/templates/article")} icon={<BsPencilSquare />} title={<>{templateTitles["article"]}<BsFire /></>} desc="Generate a whole single article for your blog" />
                    <TemplateLink click={() => navigate("/dashboard/bulk")} icon={<GrMultiple />} title={<>{templateTitles["bulk"]}<BsFire size={20} /></>} desc="Generate multiple articles with a single click" />
                    <TemplateLink click={() => navigate("/dashboard/templates/keyword")} icon={<LuKey />} title={<>{templateTitles["keyword"]}<BsFire size={20} /></>} desc="Generate keyword ideas for your articles" />
                </div>
            </div>
        </div>
    )
}