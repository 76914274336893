import './success.scss';

export default function PaymentSuccess() {
    return (
        <div id="payment-success">
            <img src="/images/vectors/order-confirmed.svg" />
            <h1>Order Confirmed</h1>
            <p>We got your order!</p>
            <p>Your credits will be in your account soon.</p>
        </div>
    )
}