import './template-link.scss';

export default function TemplateLink({
    click = () => {},
    icon,
    title,
    desc
}) {
    return (
        <div className="template" onClick={click}>
            <div className="content">
                <div className="icon">
                    {icon}
                </div>
                <div className="text">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
            </div>
            <div className="mobile">
                <p>{desc}</p>
            </div>
        </div>
    )
}