import { BsFillCartXFill } from 'react-icons/bs';
import './failed.scss';

export default function PaymentFailure() {
    return (
        <div id="payment-failure">
            <BsFillCartXFill />
            <h1>Order Failed</h1>
            <p>Your order has been cancelled. Please try again.</p>
            <p>If you think this is a bug, please contact to our support team</p>
        </div>
    )
}