import { AiFillWarning } from "react-icons/ai";
import './boxes.scss';

export default function WarnBox({ children }) {
    return (
        <div className="alert-box warnbox">
            <AiFillWarning />
            <p>{children}</p>
        </div>
    )
}