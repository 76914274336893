import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import TemplateLink from '../../../components/template';
import Selection from '../../../components/selection';
import { secondsToMMSS } from '../../../helpers/time';
import { languages } from '../../../lib/languages';
import socket from '../../../socket';

import { FaWandMagicSparkles, FaCodeCompare } from 'react-icons/fa6';
import { AiOutlineCheck, AiFillCloseCircle } from "react-icons/ai";
import { BsChevronDown, BsPencilFill, BsPencilSquare } from "react-icons/bs";
import { LiaFileAltSolid } from 'react-icons/lia';
import { PiPencilLineFill } from "react-icons/pi";
import { HiLanguage } from "react-icons/hi2";
import { FaTrashAlt } from "react-icons/fa";

import './bulk.scss';

export default function Bulk() {
    const user = useSelector(state => state.user);
    const lengths = useSelector(state => state.lengths);
    const templateTitles = useSelector(state => state.template);

    const [datas, setDatas] = useState({
        faq: true,
        image: true,
        model: "GPT-3.5",
        template: "article",
    });

    const [selections, setSelections] = useState(["titles", "keywords"]);
    const [selection, setSelection] = useState("keywords");
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(null);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const update = { ...datas };

        // Stored language
        if (localStorage.getItem("language"))
            update["language"] = localStorage.getItem("language");

        // Set article length
        if (Object.keys(lengths).includes("article"))
            update["length"] = lengths["article"][localStorage.getItem("article-length") || "short"];

        setDatas(update);
    }, []);

    useEffect(() => {
        if (user) {
            // Close previous listeners if exist
            socket.off(`bulk-listener`);

            // Socket listeners
            socket.emit("acknowledge", {...user, access_token: localStorage.getItem("login_token")});

            socket.on(`bulk-listener`, data => {
                if (data?.status === "pending") {
                    if (!loading) setLoading(true);
                    setSeconds(data.timer);

                    if (status !== data?.stats) {
                        setStatus(data?.stats);
                    }
                }

                else if (data?.status === "success") {
                    toast.success("Content successfully generated");
                    setLoading(false);
                    setStatus(null);
                    setSeconds(0);
                }

                else if (data?.message && data?.status === "failed") {
                    toast.error(data.message);
                    setLoading(false);
                    setSeconds(0);
                }

                else if (data?.status === "deleted") {
                    setLoading(false);
                    setStatus(null);
                    setSeconds(0);
                }
            });

            if (user?.role === "tier2") setDatas({
                ...datas,
                image: false,
                template: "enhanced-article"
            });
        }
    }, [user]);

    useEffect(() => {
        setSelections(["keywords", "titles"]);
        switch (datas["template"]) {
            case "enhanced-article":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                break
            case "review":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                break
            case "story":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                setSelections(["titles"]);
                setSelection("titles");
                break;
            case "comparison":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                setSelections(["titles"]);
                setSelection("titles");
                break;
            case "meta":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                setSelections(["keywords"]);
                setSelection("keywords");
                break
            case "article-2":
                setDatas({...datas, image: false, model: "GPT-3.5"});
                setSelections(["titles"]);
                setSelection("titles");
                break
            default:
                break;
        }
    }, [datas["template"]]);

    function generate() {
        if (
            (user?.role !== "tier2" && !datas?.["length"]) ||
            !["keywords", "titles"].includes(selection) ||
            !datas?.language ||
            !datas?.content
        ) {
            return toast.error("Please fill necessary data fields.");
        }

        setStatus(null);
        setSeconds(0);

        socket.emit("bulk-generate", {
            access_token: localStorage.getItem("login_token"),
            datas,
            selection
        });
    }

    function stopBulk() {
        socket.off("bulk-stopped");
        socket.emit("stop-bulk", {
            access_token: localStorage.getItem("login_token"),
        });

        socket.on("bulk-stopped", res => {
            if (res?.success) {
                setLoading(false);
                return toast.success("Bulk generation is stopped");
            }
            return toast.error("Bulk generation cannot be stopped");
        })
    }

    // Selections
    const [langSelection, setLangSelection] = useState(false);

    return (
        <div id="bulk">
            {/* Main Container */}
            {loading ? (
                <div className="loading">
                    {status && (
                        <>
                        <p style={{ marginTop: 0 }}>{status}</p>
                        <small style={{ marginTop: 20, marginBottom: 0 }}>Status:</small>
                        </>
                    )}
                    <button className="stop" onClick={stopBulk}><FaTrashAlt /> Stop Generation</button>
                    <p>At the meantime, please take a coffee, and relax ☕</p>
                    <p>Your articles are being generated right now, it may take some time.</p>
                    <p className="time">{secondsToMMSS(seconds)}</p>
                    <img src="/images/loader.svg" alt="Loading" />
                </div>
            ) : (
                <>
                    <h3>Select Template</h3>
                    <div className="templates">
                        <div className={datas["template"] === "article" ? "selected" : undefined}>
                            <TemplateLink
                                click={() => setDatas({...datas, template: "article"})}
                                icon={<BsPencilSquare />}
                                title={templateTitles["article"]}
                                desc="Generate a whole single article for your blog"
                            />
                        </div>
                        { user?.role !== "user" && (
                            <div className={datas["template"] === "enhanced-article" ? "selected" : undefined}>
                                <TemplateLink
                                    click={() => setDatas({...datas, template: "enhanced-article"})}
                                    icon={<PiPencilLineFill />}
                                    title={templateTitles["enhanced-article"]}
                                    desc="Enhanced Article"
                                />
                            </div>
                        )}
                        { user?.role !== "user" && (
                            <div className={datas["template"] === "review" ? "selected" : undefined}>
                                <TemplateLink
                                    click={() => setDatas({...datas, template: "review"})}
                                    icon={<FaWandMagicSparkles />}
                                    title={templateTitles["review"]}
                                    desc="An article type optimized for product reviews"
                                />
                            </div>
                        )}
                        <div className={datas["template"] === "comparison" ? "selected" : undefined}>
                            <TemplateLink
                                click={() => setDatas({...datas, template: "comparison"})}
                                icon={<FaCodeCompare />}
                                title={templateTitles["comparison"]}
                                desc="Compare your products"
                            />
                        </div>
                        <div className={datas["template"] === "story" ? "selected" : undefined}>
                            <TemplateLink
                                click={() => setDatas({...datas, template: "story"})}
                                icon={<FaWandMagicSparkles />}
                                title={templateTitles["story"]}
                                desc="Generate an engaging and unique story"
                            />
                        </div>
                        { user?.role !== "user" && (
                            <div className={datas["template"] === "meta" ? "selected" : undefined}>
                                <TemplateLink
                                    click={() => setDatas({...datas, template: "meta"})}
                                    icon={<LiaFileAltSolid />}
                                    title={templateTitles["meta"]}
                                    desc="Generate meta description for your blog/article"
                                />
                            </div>
                        )}
                        { user?.role !== "user" && (
                            <div className={datas["template"] === "article-2" ? "selected" : undefined}>
                                <TemplateLink
                                    click={() => setDatas({...datas, template: "article-2"})}
                                    icon={<BsPencilFill />}
                                    title={templateTitles["article-2"]}
                                    desc="Advanced articles for your blog"
                                />
                            </div>
                        )}
                    </div>

                    <h3>Select Type</h3>
                    <div className="keyword-selection">
                        { selections.includes("keywords") && (
                            <div
                                className={
                                    "single-selection " + 
                                    (selection === "keywords" ? "selected " : " ") +
                                    (selections.length === 1 ? "only " : " ")
                                }
                                onClick={() => setSelection("keywords")}
                            >
                                <h4>Keywords</h4>
                                <p>You'll use multiple keywords to create articles</p>

                                {selection === "keywords" && <div className="check"><AiOutlineCheck color='#fff' /></div>}
                            </div>
                        )}
                        { selections.includes("titles") && (
                            <div
                                className={
                                    "single-selection " + 
                                    (selection === "titles" ? "selected " : " ") +
                                    (selections.length === 1 ? "only " : " ")
                                }
                                onClick={() => setSelection("titles")}
                            >
                                <h4>Title</h4>
                                <p>You'll use multiple article titles to create articles</p>

                                {selection === "titles" && <div className="check"><AiOutlineCheck color='#fff' /></div>}
                            </div>
                        )}
                    </div>

                    { datas?.template === "article" && (
                        <>
                        <h3>Model</h3>
                        <div className="length">
                            <small>
                                { datas?.["model"] === "GPT-3.5" 
                                    ? "GPT-3.5 is a faster AI engine"
                                    : "GPT-4 is a slower but a better AI engine"
                                }
                                { datas?.["model"] === "GPT-4" && 
                                <>
                                    <br />
                                    <span>GPT-4 will cost 1.3x more</span>
                                </>
                                }
                            </small>
                            <div>
                                <button
                                    className={datas["model"] === "GPT-3.5" ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, model: "GPT-3.5"});
                                    }}
                                >GPT-3.5</button>
                                <button
                                    className={datas["model"] === "GPT-4" ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, model: "GPT-4"});
                                    }}
                                >GPT-4</button>
                            </div>
                        </div>
                        </>
                    )}

                    <h3>Content Language</h3>
                    <small>We suggest you be sure language of inputs is same as "Content Language"</small>
                    <div className="selection-container">
                        <div className="inner-box">
                            <button onClick={
                                () => setLangSelection(true)}
                            >
                                <HiLanguage />
                                {datas?.language || "Select a language"}
                                <BsChevronDown className="down-arrow" />
                            </button>

                            { datas?.language && (
                                <a
                                    className="remove"
                                    onClick={() => setDatas({ ...datas, language: null })}
                                >
                                    <AiFillCloseCircle />
                                </a>
                            )}
                        </div>
                        <div>
                            <Selection
                                items={languages}
                                click={(e) => {
                                    setDatas({ ...datas, language: e.target.innerText });
                                    localStorage.setItem("language", e.target.innerText);
                                }}
                                visible={langSelection}
                                setVisible={setLangSelection}
                            />
                        </div>
                    </div>

                    { datas?.template === "article" && (
                        <>
                        <h3>Content Length</h3>
                        <div className="length">
                            <small>Article will contain {datas?.["length"] || 4} subtitles</small>
                            <div>
                                <button
                                    className={datas["length"] == lengths["article"]?.short ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({ ...datas, length: lengths["article"]?.short });
                                        localStorage.setItem("article-length", "short");
                                    }}
                                >Short</button>
                                <button
                                    className={datas["length"] == lengths["article"]?.medium ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({ ...datas, length: lengths["article"]?.medium });
                                        localStorage.setItem("article-length", "medium");
                                    }}
                                >Medium</button>
                                <button
                                    className={datas["length"] == lengths["article"]?.long ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({ ...datas, length: lengths["article"]?.long });
                                        localStorage.setItem("article-length", "long");
                                    }}
                                >Long</button>
                                <button
                                    className={datas["length"] == lengths["article"]?.extraLong ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({ ...datas, length: lengths["article"]?.extraLong });
                                        localStorage.setItem("article-length", "extraLong");
                                    }}
                                >Extra</button>
                            </div>
                        </div>
                        </>
                    )}

                    <h3>Enter {selection}</h3>
                    <small>Please seperate different kinds of {selection} with new lines. <i>Do not</i> try to seperate with commas.</small>

                    <textarea
                        placeholder={
                            selection == "keywords"
                                ? `how to catch fish, catching a fish, fish
driving a car, how to drive a car, cars
medical help, how can I get medical help
being clumsy in a nutshell`
                                : `Tips for Catching Fishes
How to Drive a Car? Driving Car Essentials
Fishing 101: How to Catch Fish?`
                        }
                        onChange={e => setDatas({ ...datas, content: e.target.value })}
                    ></textarea>

                    { (datas["template"] === "article" || datas["template"] === "enhanced-article") && (
                        <>
                        <h3>Image Generation</h3>
                        <div className="image-container">
                            <small>Photos are provided by <a href="https://www.pexels.com">Pexels</a></small>
                            <div className="checkbox">
                                <input
                                    type="checkbox"
                                    id="include-image"
                                    onChange={e => setDatas({ ...datas, image: e.target.checked })}
                                    checked={datas?.image}
                                />
                                <label htmlFor="include-image">Find an image related with article <small>(Free)</small></label>
                            </div>
                        </div>
                        </>
                    )}

                    <div className="buttons">
                        <button className="button" onClick={generate}>Generate</button>
                    </div>
                </>
            )}
        </div >
    )
}