import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setUser } from './store/reducers/userReducer';

// Components
import Footer from './components/footer';
import Header from './components/header';

// Pages
import ToS from './pages/tos';
import Home from './pages/home';
import Login from './pages/login';
import FAQ from './pages/home/faq';
import NotFound from './pages/404';
import Confirm from './pages/confirm';
import Privacy from './pages/privacy';
import Dashboard from './pages/dashboard';
import Pricing from './pages/home/pricing';
import HowToUse from './pages/home/how-to-use';
import PaymentFailure from './pages/payments/failed';
import PaymentSuccess from './pages/payments/success';

export default function App() {

	const [params] = useSearchParams()
	const user = useSelector(state => state.user);
	const dispatch = useDispatch();
	const location = useLocation();
	
	const token = localStorage.getItem("login_token");

	const [render, setRender] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loginEnabled, setLoginEnabled] = useState(false);

	// Check if page is related with dashboard
	const [isDashboard, setIsDashboard] = useState(location.pathname.startsWith("/dashboard"));
	useEffect(() => setIsDashboard(location.pathname.startsWith("/dashboard") && user), [location.pathname, user]);

	useEffect(() => {
		fetch(process.env.REACT_APP_API + "/auth/login-enabled", {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				"access-token": token
			}
		})
		.then(data => data.json())
		.then(data => {
			let status = data?.status;
			setLoginEnabled(status || false);

			if (!status && ["/login", "/signup"].includes(location.pathname)) {
				toast.warning("Login is currently disabled, thank you for your patience.", { autoClose: false });
			}
		});

		// Get currency
		if (!localStorage.getItem("currency")) {
			fetch("https://ipapi.co/json", {
				method: "GET",
				headers: { 'Content-Type': 'application/json', }
			})
			.then(data => data.json())
			.then(data => {
				let currency = data?.currency;
				localStorage.setItem("currency", currency == "TRY" ? "TRY" : "USD");
			})
			.catch(e => {
				console.log(e);
				localStorage.setItem("currency", "USD");
			})
		}

		// Get affiliate code
		let affiliateCode = params.get("ref");
		if (affiliateCode && !user) localStorage.setItem("ref", affiliateCode);
	}, []);

	// Set user if exists
	useEffect(() => {
		if (
			token &&
			loginEnabled &&
			typeof user != 'object'
		) {
			setLoading(true);
			fetch(process.env.REACT_APP_API + "/auth/check-login", {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					"access-token": token
				}
			})
			.then(data => data.json())
			.then(data => {
				if (data?.user?.banned || !loginEnabled) {
					localStorage.removeItem("login_token");
					window.location.href = "/";
				}
				else if (data?.user && !data?.user?.banned) dispatch(setUser(data.user));
				setRender(true);
				setLoading(false);
			});
		}
		else {
			setRender(true);
			setLoading(false);
		}
	}, [user, loginEnabled]);

	if (!render) return <></>;
	return (
		<>
			{ loading &&
				<div className="main-loader">
					<img src="/images/loader.svg" />
				</div>
			}
			{ !isDashboard && <Header /> }

			<main style={isDashboard ? { maxWidth: "100%" } : undefined} className={isDashboard ? "is-dashboard" : undefined}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/faq" element={<FAQ />} />
					<Route path="/how-to-use" element={<HowToUse />} />
					<Route path="/pricing" element={<Pricing showDesc={true} />} />
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<Login page="Sign Up" />} />
					<Route path="/reset" element={<Login page="Reset" />} />
					<Route path="/confirm" element={<Confirm />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/tos" element={<ToS />} />
					<Route path="/payment/failed" element={<PaymentFailure />} />
					<Route path="/payment/success" element={<PaymentSuccess />} />

					{/* User-only Paths */}
					{user ? (
						<Route path="/dashboard/*" element={<Dashboard />} />
					) : (
						<Route path="/dashboard/*" element={<Login />} />
					)}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</main>

			{ !isDashboard && <Footer /> }
		</>
	);
}