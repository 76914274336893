export function NumberFormatter(num) {
    if (!num) return 0;
    let numArr = num.toString().split("");
    let res = "";

    for (let i = numArr.length - 1; i >= 0; i--) {
        if ((numArr.length - i - 1) % 3 === 0 && i != numArr.length - 1) res = "," + res;
        res = numArr[i] + res;
    }
    return res;
}