import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import { LuKey } from "react-icons/lu";
import { GrMultiple } from "react-icons/gr";
import { HiLanguage } from 'react-icons/hi2';
import { FaWandMagicSparkles } from "react-icons/fa6";
import { BsPencilSquare, BsMagic } from 'react-icons/bs';
import { RiAdvertisementLine, RiBook2Fill } from "react-icons/ri";
import { HiSortDescending, HiSortAscending } from "react-icons/hi";
import { IoIosCheckmarkCircleOutline, IoMdCloseCircle } from "react-icons/io";
import { LiaPencilRulerSolid, LiaChartBarSolid, LiaPenNibSolid, LiaFileAltSolid } from "react-icons/lia";

import { setUser } from "../../../store/reducers/userReducer";
import TemplateLink from "../../../components/template";

import './settings.scss';

export default function Settings() {

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const templateTitles = useSelector(state => state.template);

    const [disabledTemplates, setDisabledTemplates] = useState([]);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [changedName, setChangedName] = useState("");
    const [changedKeys, setChangedKey] = useState([]);

    const templateElements = {
        "article"          : <TemplateLink title={templateTitles["article"]} desc="Generate a whole single article for your blog" icon={<BsPencilSquare />} />,
        "bulk"             : <TemplateLink title={templateTitles["bulk"]} desc="Generate multiple articles with a single click" icon={<GrMultiple />} />,
        "keyword"          : <TemplateLink title={templateTitles["keyword"]} desc="Generate keyword ideas for your articles" icon={<LuKey />} />,
        "title"            : <TemplateLink title={templateTitles["title"]} desc="Generate SEO-friendly corresponding titles for your keywords" icon={<LiaPenNibSolid />} />,
        "title-spinner"    : <TemplateLink title={templateTitles["title-spinner"]} desc="Enter a title, get a better one in terms of SEO" icon={<BsMagic />} />,
        "spinner"          : <TemplateLink title={templateTitles["spinner"]} desc="Enter your content and get a spinned version" icon={<LiaPencilRulerSolid />} />,
        "meta"             : <TemplateLink title={templateTitles["meta"]} desc="Generate meta description for your blog/article" icon={<LiaFileAltSolid />} />,
        "intro"            : <TemplateLink title={templateTitles["intro"]} desc="Generate introduction paragraphs for your article" icon={<HiSortAscending />} />,
        "conclusion"       : <TemplateLink title={templateTitles["conclusion"]} desc="Generate conclusion paragraphs for your article" icon={<HiSortDescending />} />,
        "faq"              : <TemplateLink title={templateTitles["faq"]} desc={"Generate \"Frequently Asked Questions\" section for your article/blog"} icon={<LiaChartBarSolid />} />,
        "translate"        : <TemplateLink title={templateTitles["translate"]} desc="Enter your content and translate it to any language you want" icon={<HiLanguage />} />,
        "marketing"        : <TemplateLink title={templateTitles["marketing"]} desc="Generate a marketing text for your brand" icon={<RiAdvertisementLine />} />,
        "book"             : <TemplateLink title={templateTitles["book"]} desc="Generate summary and review of a book" icon={<RiBook2Fill />} />,
        "story"            : <TemplateLink title={templateTitles["story"]} desc="Generate an engaging and unique story" icon={<FaWandMagicSparkles />} />,
    }

    useEffect(() => {
        if (user?.disabledTemplates) setDisabledTemplates(user.disabledTemplates);
        if (user?.customOpenAiKey) setChangedKey(user.customOpenAiKey);
    }, [user]);

    function handleLink(key) {
        fetch(process.env.REACT_APP_API + "/user/disable-template", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                template: key
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) {
                setDisabledTemplates(data.templates);
                dispatch(setUser({ ...user, disabledTemplates: data.templates }));
            } else toast.error(data?.message);
        });
    }

    function changeName() {
        if (changedName === user?.name) return toast.error("Your name cannot be same");

        fetch(process.env.REACT_APP_API + "/user/change-name", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                name: changedName
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) {
                toast.success("Your name is successfully changed");
                dispatch(setUser({ ...user, name: changedName }));
            } else toast.error(data?.message);
        });
    }

    function changePassword() {
        if (oldPassword === newPassword) return toast.error("Old and new password cannot be same");

        fetch(process.env.REACT_APP_API + "/user/change-password", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                oldPassw: oldPassword,
                newPassw: newPassword
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) {
                toast.success("Your password is successfully changed");
            } else toast.error(data?.message);
        });
    }

    function changeOpenAiKey(API_KEYS = undefined) {
        if (API_KEYS === undefined) API_KEYS = changedKeys;

        fetch(process.env.REACT_APP_API + "/user/change-api-key", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                "keys": API_KEYS
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) {
                if (API_KEYS?.length) toast.success("You've successfully set an OpenAI API key");
                else toast.success("You've successfully removed OpenAI API key");
            } else toast.error(data?.message);
        });
    }

    return (
        <div id="settings">
            <h1>User Settings</h1>

            <h2>Change your Name</h2>
            <section>
                <input
                    onChange={e => setChangedName(e.target.value)}
                    placeholder="Tony Stark"
                    defaultValue={user?.name}
                />
                <button
                    onClick={changeName}
                    className="save save-name"
                >
                    <IoIosCheckmarkCircleOutline />
                </button>
            </section>

            <h2>Change your Password</h2>
            <section>
                <p>Current Password</p>
                <input
                    onChange={e => setOldPassword(e.target.value)}
                    type="password"
                    placeholder="*******"
                />

                <p>New Password</p>
                <input
                    onChange={e => setNewPassword(e.target.value)}
                    type="password"
                    placeholder="*******"
                />
                <button onClick={changePassword} className="send">Change Password</button>
            </section>

            <h2>OpenAI API Key <small>(Optional)</small></h2>
            <p>Enter OpenAI API keys if you want to use your own.</p>
            <section>
                <textarea
                    onChange={e => {
                        let val = e.target.value;
                        const arr = [];
                        for (let line of val.split('\n')) {
                            arr.push(line);
                        }
                        setChangedKey(arr);
                    }}
                    placeholder="sk-***"
                    spellCheck={false}
                    value={changedKeys.join("\n")}
                >
                </textarea>

                <span className="key-count">{changedKeys.length}</span>

                <button
                    onClick={() => changeOpenAiKey(undefined)}
                    className="save"
                >
                    <IoIosCheckmarkCircleOutline />
                </button>

                <button
                    onClick={() => {
                        setChangedKey([]);
                        changeOpenAiKey([]);
                    }}
                    className="delete"
                >
                    <IoMdCloseCircle />
                </button>
            </section>

            <h1>Customization</h1>

            <h2>Available Templates</h2>
            <p>You can disable some templates if you don't want to see them on <Link to="/dashboard/templates">Generate</Link> page</p>
            <section className="templates">
                { Object.keys(templateElements).map((key, i) => {
                    return (
                        <div
                            className={ disabledTemplates.includes(key) ? "disabled" : "" }
                            onClick={() => handleLink(key)}
                            key={i}
                        >
                            {templateElements[key]}
                        </div>
                    )
                })}
            </section>
        </div>
    )
}