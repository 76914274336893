import { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './login.scss';

export default function Login({ page = "Login" }) {

    const user = useSelector(state => state.user);
    const [queryParameters] = useSearchParams();
    const navigate = useNavigate();

    const [method, setMethod] = useState(page);
    const [datas, setDatas] = useState({});
    const [loading, setLoading] = useState(false);

    // Change method and title
    useEffect(() => {
        setMethod(page);
        document.title = page + " - DURBUN";
    }, [page]);
    
    // Check for an user
    useEffect(() => {
        if (user) navigate("/dashboard");
    }, [user]);

    function login() {
        if (!datas?.email || !datas?.password) {
            toast.error("Please fill all of the fields");
            return;
        } else {
            fetch(process.env.REACT_APP_API + "/auth/login", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    datas: datas
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.token && data?.success) {
                    localStorage.setItem("login_token", data.token);
                    window.location.href = "/dashboard";
                }
                else if (data?.message && !data?.success) {
                    toast.error(data.message);
                }
            });
        }
    }

    function register() {
        if (!datas?.checked) {
            toast.error("Please confirm ToS and Privacy Policy to sign up");
            return;
        }
        else if (datas?.password !== datas?.re_password) {
            toast.error("Passwords do not match");
            return;
        }
        else if (datas?.password?.length < 6) {
            toast.error("Your password length must be at least 6 characters");
            return;
        }
        else if (!datas?.name || !datas?.email || !datas?.password || !datas?.email) {
            toast.error("Please fill all of the fields");
            return;
        }
        else {
            let obj = { ...datas };
            delete obj["re_password"];
            delete obj["checked"];

            let affiliateCode = localStorage.getItem("ref");
            if (affiliateCode) obj["ref"] = affiliateCode;

            setLoading(true);

            fetch(process.env.REACT_APP_API + "/auth/register", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    datas: obj
                })
            })
            .then(data => data.json())
            .then(data => {
                if (!data?.success && data?.message) toast.error(data.message);
                else if (data?.success && data?.confirmation) setMethod("Confirmation");
                else if (data?.success && !data?.confirmation) {
                    toast.success("You've been successfully signed up. Please login.");
                    setMethod("Login");
                }
                setLoading(false);
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
            });
        }
    }

    function resetLink() {
        if (!datas?.email) {
            toast.error("Please fill all of the fields");
            return;
        } else {
            setLoading(true);

            fetch(process.env.REACT_APP_API + "/auth/reset-password-link", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    datas: datas
                })
            })
            .then(data => data.json())
            .then(data => {
                if (!data?.success && data?.message) toast.error(data.message);
                else if (data?.success) toast.success("A reset link has been sent to your E-Mail, please check your inbox.");
                setLoading(false);
            })
            .catch(e => {
                console.error(e);
                setLoading(false);
            });
        }
    }

    function reset() {
        let id = queryParameters.get("id");

        if (!id) {
            navigate("/login");
            return;
        }
        if (datas?.password !== datas?.re_password) {
            toast.error("Passwords do not match");
            return;
        }
        else if (!datas?.password) {
            toast.error("Please fill all of the fields");
            return;
        }
        else if (datas?.password?.length < 6) {
            toast.error("Your password length must be at least 6 characters");
            return;
        }
        else {
            fetch(process.env.REACT_APP_API + "/auth/reset-password", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    password: datas.password,
                    id: id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (!data?.success && data?.message) toast.error(data.message);
                else if (data?.success) toast.success("Your password successfully reset, you're being redirected.");
                setInterval(() => window.location.href = "/login", 2000);
            })
            .catch(e => {
                console.error(e);
            });
        }
    }

    return (
        <div id="login">
            <div className="content">
                <div className="form">
                    {!loading ? (<>
                    {method == "Login" && (
                        <div className="login-container">
                            <h3>LOGIN</h3>
                            <span>E-Mail</span>
                            <input placeholder="admin@example.com" type="email" value={datas?.email || ""} onChange={e => setDatas({ ...datas, email: e.target.value })} />
                            <span>Password</span>
                            <input placeholder="*****" type="password" value={datas?.password || ""} onChange={e => setDatas({ ...datas, password: e.target.value })} />

                            <div className="links">
                                <button onClick={login}>Login</button>
                                <p onClick={() => setMethod("Password Reset")}>I forgot my password</p>
                            </div>
                            <p onClick={() => {
                                setMethod("Sign Up");
                                navigate("/signup");
                            }}>I don't have an account</p>
                        </div>
                    )}
                    {method == "Sign Up" && (
                        <div className="register-container">
                            <h3>SIGN UP</h3>
                            <span>Name</span>
                            <input placeholder="Tony Stark" type="text" value={datas?.name || ""} onChange={e => setDatas({ ...datas, name: e.target.value })} />
                            <span>E-Mail</span>
                            <input placeholder="admin@example.com" type="email" value={datas?.email || ""} onChange={e => setDatas({ ...datas, email: e.target.value })} />
                            <span>Password</span>
                            <input placeholder="*****" type="password" value={datas?.password || ""} onChange={e => setDatas({ ...datas, password: e.target.value })} />
                            <span>Re-Enter Password</span>
                            <input placeholder="*****" type="password" value={datas?.re_password || ""} onChange={e => setDatas({ ...datas, re_password: e.target.value })} />

                            <div>
                                <input type="checkbox" checked={datas?.checked || false} onChange={e => setDatas({ ...datas, checked: e.target.checked })} />
                                <p>I have read and agree to the <Link to="/tos">Terms of Usage</Link> and <Link to="/privacy">Privacy Policy</Link>.</p>
                            </div>

                            <div className="links">
                                <button onClick={register}>Sign Up</button>
                                <p onClick={() => {
                                    setMethod("Login");
                                    navigate("/login");
                                }}>I have an account</p>
                            </div>
                        </div>
                    )}
                    {method == "Password Reset" && (
                        <div className="reset-password-container">
                            <h3>RESET PASSWORD</h3>
                            <span>E-Mail</span>
                            <input placeholder="admin@example.com" type="email" onChange={e => setDatas({ ...datas, email: e.target.value })} />
                            
                            <div className="links">
                                <button onClick={resetLink}>Send Reset Link</button>
                                <p onClick={() => setMethod("Login")}>Go back to login</p>
                            </div>
                        </div>
                    )}
                    {method == "Confirmation" && (
                        <div>
                            <h4>Confirm your Account</h4>
                            <p>A confirmation E-Mail has been sent, please check your inbox</p>
                        </div>
                    )}
                    {method == "Reset" && (
                        <div>
                            <span>Password</span>
                            <input placeholder="*****" type="password" value={datas?.password || ""} onChange={e => setDatas({ ...datas, password: e.target.value })} />
                            <span>Re-Enter Password</span>
                            <input placeholder="*****" type="password" value={datas?.re_password || ""} onChange={e => setDatas({ ...datas, re_password: e.target.value })} />

                            <div className="links">
                                <button onClick={reset}>Reset Password</button>
                            </div>
                        </div>
                    )}
                    </>) : (
                    <div className="loading">
                        <img src="/images/loader.svg" />
                    </div>
                    )}
                </div>
                <div className="text">
                    <h2>{method}</h2>
                    <p>
                        {method == "Login" && "Login to see your articles and generate new ones in the Dashboard."}
                        {method == "Sign Up" && "Signup to durbun.net to be able to use our advanced services."}
                        {method == "Password Reset" && "Enter your email and we'll send you a password reset link."}
                        {method == "Confirmation" && "Enable your account with clicking the confirmation link that sent to your E-Mail."}
                        {method == "Reset" && "Change your password to a new one"}
                    </p>
                </div>
            </div>
        </div>
    )
}