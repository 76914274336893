import { createSlice } from "@reduxjs/toolkit";

export const articlesSlice = createSlice({
    name: "articles",
    initialState: {
        data: [],
        status: "pending",
        max: 0,
    },
    reducers: {
        setArticles: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setArticles } = articlesSlice.actions;
export default articlesSlice.reducer;