import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { animateScroll } from "react-scroll";
import { BsChevronLeft, BsCheckAll } from 'react-icons/bs';
import { BiSolidPaperPlane } from "react-icons/bi";
import { setTickets } from '../../../store/reducers/ticketsReducer';
import './tickets.scss';

export default function Tickets() {

    const tickets = useSelector(state => state.tickets);
    const dispatch = useDispatch();
    const pathname = useLocation().pathname;
    const [searchParams] = useSearchParams();

    const [method, setMethod] = useState("tickets");
    const [ticket, setTicket] = useState(null);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [message, setMessage] = useState("");
    const [seenUpdated, setSeenUpdated] = useState(false);

    useEffect(() => {
        // Scroll to bottom
        let scrollTimeout = setTimeout(() => {
            animateScroll.scrollToBottom({ duration: 1, containerId: "message-body" });
            clearInterval(scrollTimeout);
        }, 1);

        // Get ticket
        if (tickets?.length) {
            let id = searchParams.get("id");
            let raw_ticket = tickets.filter(t => t.id == id)?.[0] || null;
            setTicket(raw_ticket);
            setMethod("ticket");
        }

        if (pathname == "/dashboard/tickets") setMethod("tickets");
    }, [pathname, tickets]);

    useEffect(() => {
        // Set seen data
        if (ticket && !seenUpdated) {
            setSeenUpdated(true);
            fetch(process.env.REACT_APP_API + "/ticket/set-seen", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    id: ticket.id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.success) {
                    let tickets_raw = JSON.parse(JSON.stringify(tickets));
                    for (let i = 0; i < tickets_raw.length; i++) {
                        let ticket_raw = tickets_raw[i];

                        if (ticket.id == ticket_raw.id) {
                            ticket_raw.messages = ticket_raw.messages.map((m, i) => {
                                if (m.seen == false && m.sender == "admin") m.seen = true;
                                return m;
                            });
                            dispatch(setTickets(tickets_raw));
                            break;
                        }
                    }
                }
            });
        }
    }, [ticket]);

    useEffect(() => {
        if (method == "tickets") setSeenUpdated(false);
    }, [method]);

    function refetch() {
        fetch(process.env.REACT_APP_API + "/ticket/get-tickets", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data?.tickets) dispatch(setTickets(data.tickets));
            if (ticket) {
                for (let i = 0; i < data?.tickets?.length; i++) {
                    if (ticket.id == data?.tickets?.[i]?.id) {
                        setTicket(data.tickets[i]);
                        break;
                    }
                }
            }
        });
    }

    function create() {
        if (title && content) {
            fetch(process.env.REACT_APP_API + "/ticket/create-ticket", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    title: title,
                    content: content
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.success) {
                    toast.success("Ticket successfully created.");
                    setMethod("tickets");
                    refetch();
                } else {
                    toast.error(data?.message || "An error occured, please contact with support team.");
                }
            });
        } else {
            toast.error("Please fill all of the input fields.");
        }
    }

    function sendMessage() {
        if (message && ticket) {
            fetch(process.env.REACT_APP_API + "/ticket/send-message", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    message,
                    id: ticket.id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.success) {
                    refetch();
                    setMessage("");
                }
                else toast.error(data?.message || "An error occured, please contact with support team.");
            });
        } else {
            toast.error("Please enter a message to send");
        }
    }

    return (
        <div id="tickets">
            {!ticket ? (
                <>
                <div className="top">
                    {method == "tickets" &&
                        <>
                            <span>Do you have a problem, do you want to report a bug? Just create a ticket and we'll get in touch.</span>
                            <button onClick={() => setMethod("create")}>Create a Ticket</button>
                        </>
                    }
                    {method == "create" && <button className="link-type" onClick={() => setMethod("tickets")}><BsChevronLeft />Go Back to Tickets</button>}
                </div>
                <div className="body">
                    {method == "tickets" &&
                        <div className={"tickets " + (tickets?.length == 0 ? "empty" : "")}>
                            { tickets?.length == 0 ? (
                                <p className="empty-text">There are no tickets to show. Let's <a onClick={() => setMethod("create")}>create</a> one.</p>
                            ) : (
                                <>
                                    { tickets.filter(t => t.status == "Active").map((ticket, i) => {
                                        let lastMessage = ticket.messages[ticket.messages.length - 1]
                                        return (
                                            <Link to={"/dashboard/tickets/ticket?id=" + ticket.id} key={i}>
                                                <div className={"ticket " + (ticket.status.toLowerCase())} key={i}>
                                                    <div>
                                                        {ticket.messages.map((m, i) => {
                                                            if (m.seen == false && m.sender == "admin") return <span key={i} className="notification"></span>
                                                        })}
                                                        <h4>{ticket.title}</h4>
                                                    </div>
                                                    <div>
                                                        <p>{lastMessage.message}</p>
                                                        <div className="status">
                                                            <div className="status-icon"></div>
                                                            {ticket.status}
                                                        </div>
                                                        <div className="date">{new Date(lastMessage.time).toLocaleString()}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                    { tickets.filter(t => t.status == "Closed").map((ticket, i) => {
                                        let lastMessage = ticket.messages[ticket.messages.length - 1]
                                        return (
                                            <Link to={"/dashboard/tickets/ticket?id=" + ticket.id} key={i}>
                                                <div className={"ticket " + (ticket.status.toLowerCase())} key={i}>
                                                    <div>
                                                        {ticket.messages.map((m, i) => {
                                                            if (m.seen == false && m.sender == "admin") return <span key={i} className="notification"></span>
                                                        })}
                                                        <h4>{ticket.title}</h4>
                                                    </div>
                                                    <div>
                                                        <p>{lastMessage.message}</p>
                                                        <div className="status">
                                                            <div className="status-icon"></div>
                                                            {ticket.status}
                                                        </div>
                                                        <div className="date">{new Date(lastMessage.time).toLocaleString()}</div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </>
                            )}
                        </div>
                    }
                    {method == "create" &&
                        <div className="create">
                            <div className="form">
                                <div>
                                    <h3>Subject</h3>
                                    <p>Try to summarize your message in one or two sentence.</p>
                                    <input
                                        maxLength={100}
                                        placeholder="My article couldn't be created and my credits are gone"
                                        onChange={e => setTitle(e.target.value)}
                                    />
                                </div>
                                <div className="textarea-container">
                                    <h3>Type your Message</h3>
                                    <p>Provide as much as detail you can, we'll try to help you as much as we can.</p>
                                    <textarea maxLength={3000} onChange={e => setContent(e.target.value)}></textarea>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {method == "create" &&
                    <div className="bottom">
                        <button onClick={create}>Submit</button>
                    </div>
                }
                </>
            ) : (
                <div id="ticket">
                    <div className="top">
                        <h2>{ticket.title}</h2>
                        <Link to="/dashboard/tickets" className="link-type"><BsChevronLeft /><span>Go Back to Tickets</span></Link>
                    </div>
                    <div className="body" id="message-body">
                        <div className="messages">
                            { ticket.messages.map((m, i) => {
                                let time = new Date(m.time).toLocaleString();
                                
                                return (
                                    <div className={"message " + m.sender} key={i}>
                                        <div className="content">{m.message}</div>
                                        <div className="info">
                                            <span>{time}</span>
                                            {m.sender == "user" && <BsCheckAll color={m.seen ? "#00a2ee" : "gray"} /> }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="bottom">
                        <input
                            value={message}
                            disabled={ticket.status != "Active"}
                            onChange={e => setMessage(e.target.value)}
                            onKeyDown={e => {
                                if (e.key == "Enter") sendMessage();
                            }}
                        />
                        { ticket.status == "Active" && <BiSolidPaperPlane onClick={sendMessage} />}
                    </div>
                </div>
            )}
        </div>
    )
}