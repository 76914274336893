import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { FiZap, FiTrendingUp, FiUsers, FiPenTool } from "react-icons/fi";
import { BsPencilSquare, BsWordpress } from "react-icons/bs";
import { AiOutlinePicture } from "react-icons/ai";
import { LiaPenNibSolid } from 'react-icons/lia';
import { RiAdvertisementLine, RiBook2Fill } from 'react-icons/ri';
import { FaGears } from "react-icons/fa6";
import { LuKey } from 'react-icons/lu';

import Nav from "./nav";
import FAQ from './faq';
import Pricing from "./pricing";
import HowToUse from "./how-to-use";
import Brand from "../../components/brand";
import PlainEditor from '../../components/plain';

import { demo as demo_data } from '../../data/demo';

import './home.scss';

export default function Home() {

    const [demo, setDemo] = useState("article");

    useEffect(() => {
        document.title = "DURBUN | Generate SEO-friendly AI Articles in seconds";
    }, []);

    return (
        <div id="home">
            <Nav />

            <div className="dashboard-image">
                <div className="image-container">
                    <img src="/images/dashboard.png" className="dashboard-img" />
                </div>
            </div>

            <div id="our-service">
                <h2>Our Service</h2>

                <p><Brand /> is a trusted SEO-friendly article-generation service that uses AI technology.</p>
                <p>You can generate <b>SEO-friendly articles, FAQ content, meta descriptions, marketing texts, keywords</b>... There is even a <b>content</b> and <b>title spinner</b>!</p>
                <p>Generating featured images for your SEO-friendly article is an extra feature that is free. You can also publish them on your WordPress website with just one click!</p>
            </div>

            <div id="demo">
                <div className="prompts">
                    <div
                        className={"prompt " + (demo === "article" ? "active" : "")}
                        onClick={() => setDemo("article")}
                    >
                        <div className="icon"><BsPencilSquare color='#fc6d00' /></div>
                        <div>
                            <p>SEO-Friendly Article Generator</p>
                            <span>ai content generator, artificial intelligence</span>
                        </div>
                    </div>
                    <div
                        className={"prompt " + (demo === "keyword" ? "active" : "")}
                        onClick={() => setDemo("keyword")}
                    >
                        <div className="icon"><LuKey color='#fc6d00' /></div>
                        <div>
                            <p>Keyword Generator</p>
                            <span>studying abroad, international students, college</span>
                        </div>
                    </div>
                    <div
                        className={"prompt " + (demo === "title" ? "active" : "")}
                        onClick={() => setDemo("title")}
                    >
                        <div className="icon"><LiaPenNibSolid color='#fc6d00' /></div>
                        <div>
                            <p>Title Generator</p>
                            <span>autoblogging, seo, ai</span>
                        </div>
                    </div>
                    <div
                        className={"prompt " + (demo === "marketing" ? "active" : "")}
                        onClick={() => setDemo("marketing")}
                    >
                        <div className="icon"><RiAdvertisementLine color='#fc6d00' /></div>
                        <div>
                            <p>Marketing</p>
                            <span>durbun.net is an SEO-Friendly AI content generator</span>
                        </div>
                    </div>
                    <div
                        className={"prompt " + (demo === "book" ? "active" : "")}
                        onClick={() => setDemo("book")}
                    >
                        <div className="icon"><RiBook2Fill color='#fc6d00' /></div>
                        <div>
                            <p>Book Summary</p>
                            <span>A Brief History of Time</span>
                        </div>
                    </div>
                </div>
                <div className="result">
                    <PlainEditor content={demo_data[demo]} />
                </div>
            </div>

            <div id="features">
                <div>
                    <div className="feature">
                        <div>
                            <FiUsers size={60} />
                        </div>
                        <div>
                            <h3>SEO-Friendly Articles</h3>
                            <p>Generated articles contain titles, keywords, subtitles, images, and even a FAQ section.. It's perfect for the overall SEO score.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <BsWordpress size={60} />
                        </div>
                        <div>
                            <h3>WordPress Integration</h3>
                            <p>You can publish your articles in WordPress through our dashboard. You don't have to copy & paste articles one by one, we let you post all of your articles with one click!</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <AiOutlinePicture size={60} />
                        </div>
                        <div>
                            <h3>Article Images</h3>
                            <p>Your articles can contain multiple images including a thumbnail. This feature is especially handy for WordPress posts.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <FiPenTool size={60} />
                        </div>
                        <div>
                            <h3>Bulk Generation</h3>
                            <p>You can generate multiple articles at the same time. Just enter your keywords and leave rest of the work to <Brand />.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <FiZap size={60} />
                        </div>
                        <div>
                            <h3>Faster than Light</h3>
                            <p>You can literally create hundreds of articles in minutes! With our advanced prompts, you can generate SEO-friendly articles in no time.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <FiTrendingUp size={60} />
                        </div>
                        <div>
                            <h3>No Extra Costs & Fees</h3>
                            <p>You use what you pay. We never demand more for our services. Buy and use your <Brand /> credits to generate articles.</p>
                        </div>
                    </div>
                    {/*<div className="feature">
                        <div>
                            <FaYoutube size={60} />
                        </div>
                        <div>
                            <h3>Add Youtube Video</h3>
                            <p>You can add a random related Youtube video to your article with only one click. It'll boost the SEO of the article</p>
                        </div>
                    </div>*/}
                    <div className="feature">
                        <div>
                            <FaGears size={60} />
                        </div>
                        <div>
                            <h3>GPT-4 Support</h3>
                            <p>You can use GPT-4 for content generation to generate more advanced contents.</p>
                        </div>
                    </div>
                    <div className="feature">
                        <div>
                            <LuKey size={60} />
                        </div>
                        <div>
                            <h3>Keyword Generation</h3>
                            <p>Tired of manually searching keywords for a content idea? There's a keyword generation tool in <Brand /> which allows you to populate bulk keywords.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Pricing */}
            <div className="pricing-container">
                <Pricing />
            </div>

            <div className="dashboard-image">
                <div className="image-container">
                    <img src="/images/dashboard2.png" className="dashboard-img" />
                </div>
            </div>

            {/* How to Use */}
            <div className="howtouse-container">
                <HowToUse />
            </div>

            <div id="contact">
                <div>
                    <h2>Contact Us</h2>
                    <p>Did you encounter a problem or a bug? Did you have problems generating your articles and waste your credits? Don't hesitate to contact us, we'd like to help you!<br/><br/> You can email us <span>(contact@durbun.net)</span>, or you can <Link to="/dashboard/tickets">create a support ticket</Link> dashboard.</p>
                </div>
            </div>

            {/* FAQ */}
            <div className="faq-container">
                <img src="/images/vectors/wave-1.svg" className="wave-1" />
                <FAQ />
                <img src="/images/vectors/wave-2.svg" className="wave-2" />
            </div>

            <div className="dashboard-image multiple">
                <div className="image-container">
                    <img src="/images/dashboard3.png" className="dashboard-img" />
                    <img src="/images/dashboard4.png" className="dashboard-img" />
                    <img src="/images/dashboard5.png" className="dashboard-img" />
                </div>
            </div>

            <div id="signup-now">
                <p>What are you waiting for? Join us and get your first 5000 words for free!</p>
                <Link to="/signup">SIGN UP</Link>
            </div>
        </div>
    )
}