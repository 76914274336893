import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FiUser, FiLink, FiKey, FiTrash } from 'react-icons/fi';
import { setWebsites } from '../../../store/reducers/websitesReducer';
import './websites.scss';

export default function Websites() {

    const dispatch = useDispatch();
    const websites = useSelector(state => state.websites);

    const [url, setUrl] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [adding, setAdding] = useState(false);

    function refetch() {
        fetch(process.env.REACT_APP_API + "/website/get-websites", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            dispatch(setWebsites(data?.websites || []));
        });
    }

    // Add Website
    function add() {
        if (!url || !username || !password)
            return toast.error("Please fill all of the fields.");

        setAdding(true);

        fetch(process.env.REACT_APP_API + "/website/add", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                url, username, password
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) toast.success("Website successfully added");
            else if (!data?.success && data?.message) toast.error(data.message);
            setAdding(false);
            refetch();
        });
    }

    // Delete Website
    function remove(url) {
        fetch(process.env.REACT_APP_API + "/website/delete", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({ url })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) toast.success("Website connection successfully removed");
            else if (!data?.success && data?.message) toast.error(data.message);
            refetch();
        });
    }

    return (
        <div id="websites">
            <div className="top">
                <div className="add-website">
                    <div className="form">
                        <div className="form-section">
                            <div className="text">
                                <label>Website URL</label>
                                <small>Paste the URL that contains WordPress folders</small>
                            </div>
                            <div className="input">
                                <FiLink />
                                <input placeholder="https://durbun.net" onChange={e => setUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-section">
                            <div className="text">
                                <label>WordPress Username</label>
                                <small>User should have access to submiting posts</small>
                            </div>
                            <div className="input">
                                <FiUser />
                                <input placeholder="Admin" onChange={e => setUsername(e.target.value)} />
                            </div>
                        </div>
                        <div className="form-section">
                            <div className="text">
                                <label>Application Password</label>
                                <small><a href="https://make.wordpress.org/core/2020/11/05/application-passwords-integration-guide/" target='_blank'>How to get application password</a></small>
                            </div>
                            <div className="input">
                                <FiKey />
                                <input placeholder="abcd EFGH 1234 ijkl MNOP 6789" onChange={e => setPassword(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="button">
                        { adding ? <img src="/images/loader.svg" /> : <button onClick={add}>Connect Website</button> }
                    </div>
                </div>
            </div>
            <div className="body">
                <div className={"websites " + (websites?.length == 0 ? "empty" : "")}>
                    {websites?.length == 0 ? (
                        <p className="empty-text">There are no websites to show. Connect your WordPress website from the form above.</p>
                    ) : (
                        <>
                            {websites.map((website, i) => {
                                return (
                                    <div className="website" key={i}>
                                        <div>
                                            <div className="favicon">
                                                <img src={ `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${website.url}&size=256` || "/images/default-website.png" } />
                                            </div>
                                            <div>
                                                <a href={ website.url } target="_blank">{ website.url }</a>
                                            </div>
                                        </div>
                                        <div>
                                            <a onClick={() => remove(website.url)}><FiTrash /></a>
                                        </div>
                                    </div>
                                )
                            })}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}