import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { convert } from 'html-to-text';

import { publish } from "../../../helpers/publish";
import { setArticles } from "../../../store/reducers/articlesReducer";

import {
    BsChevronDown,
    BsChevronLeft,
    BsChevronRight,
    BsDownload
} from "react-icons/bs";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { FaTrashCan } from "react-icons/fa6";
import { GrWordpress } from "react-icons/gr";

import Article from "./article";
import Brand from "../../../components/brand";
import Selection from "../../../components/selection";
import PublishModal from "../../../components/publish-modal";
import DownloadModal from "../../../components/download-modal";
import ConfirmationModal from "../../../components/confirmation-modal";

import './articles.scss';

const articleTypes = ["article", "enhanced-article", "review", "story", "comparison", "article-2"];

export default function Articles() {

    /** STATES **/
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const articles = useSelector(state => state.articles);
    const templateTitles = useSelector(state => state.template);

    const [ID, setID] = useState(null);
    const [loading, setLoading] = useState(articles.status === "fetched");

    // Article-related
    const [filter, setFilter] = useState({});
    const [publishing, setPublishing] = useState(false);

    // Selection
    const [selected, setSelected] = useState({
        $match: { $or: [{ id: null }] },
    });
    const [selectedCount, setSelectedCount] = useState(0);
    const [inSelections, setInSelections] = useState([]);
    const [ninSelections, setNinSelections] = useState([]);
    const [publishablesSelected, setPublishablesSelected] = useState(false);

    // Pagination
    const [perPage, setPerPage] = useState(20);
    const [page, setPage] = useState(1);

    // Group Selection
    const [groups, setGroups] = useState([]);
    const [groupType, setGroupType] = useState("All Content");

    // Modals
    const [deleteModal, setDeleteModal] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);

    // Dropdowns
    const [groupSelection, setGroupSelection] = useState(false);

    /** USE-EFFECTS **/
    useEffect(() => { refetch({}, filter?.content?.length) }, [filter]);

    useEffect(() => {
        // Get Groups
        fetch(process.env.REACT_APP_API + "/article/get-groups", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data?.group_articles) setGroups(data.group_types);
            else console.error(data.message);
        });

        // Stop loader if there are already artiles
        if (articles.data?.length) setLoading(false);
    }, []);

    // Router
    useEffect(() => {
        let id = params.get("id");
        if (id) setID(id);
        else setID(null);
    }, [params]);

    // Change Group
    useEffect(() => {
        setPage(1);

        switch(groupType) {
            case "All Content":
                if (JSON.stringify(filter) !== "{}")
                    setFilter({});
                break;
            default:
                setFilter({ bulk: groups[groupType] });
        }
    }, [groupType]);

    /** FUNCTIONS **/
    function refetch(options, noLoader = false) {
        return new Promise((resolve, reject) => {
            if (!noLoader) {
                setLoading(true);

                dispatch(setArticles({
                    data: [],
                    status: "pending",
                    max: 0
                }));
            }
    
            fetch(process.env.REACT_APP_API + "/article/get-articles", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    filter: options?.filter || filter,
                    page: options?.page || page,
                    perPage: options?.perPage || perPage,
                })
            })
            .then(data => data.json())
            .then(data => {
                setLoading(false);

                if (data?.articles) {
                    dispatch(setArticles({
                        data: data.articles,
                        status: "fetched",
                        max: data.max,
                    }));
                } else {
                    dispatch(setArticles({
                        data: articles.data,
                        status: "fetched",
                        max: articles.max
                    }));
                }

                if (!data?.success && data?.message) {
                    toast.error(data.message);
                }

                return resolve();
            });
        });
    }

    /* Selection Functions */
    function handleSelect(
        type,
        checked = false,
        article = null
    ) {
        if (type === "single") {
            const id = article.id;

            let _ins = [...inSelections],
                _nins = [...ninSelections];

            // Add to selected
            if (checked) {
                setSelectedCount(c => c + 1);

                if (_nins.includes(id))
                    _nins.splice(_nins.indexOf(id), 1);
                else _ins = [..._ins, id];
            }
            // Remove from selected
            else {
                setSelectedCount(c => c - 1);

                if (_ins.includes(id))
                    _ins.splice(_ins.indexOf(id), 1);
                else _nins = [..._nins, id];
            }

            let $match = {
                $or: [
                    { id: _ins.length
                        ? { $in: _ins }
                        : null
                    },
                ]
            };

            /// 3 Possibilities:

            // 1) Previous state of $match was not "single", and $in was not empty
            // Length was 2, still gonna be 2
            if (selected.$match.$or.length === 2) {
                $match.$or[1] = selected.$match.$or?.[1];

                if (_nins.length && $match.$or[1]?.id)
                    $match.$or[1].id = { ...$match.$or[1].id, $nin: _nins };
                else if (_nins.length)
                    $match.$or[1].id = { $nin: _nins };
            }

            else if (selected.$match.$or.length === 1) {
                // 2) Previous state of $match was not "single", and $in was empty
                // Length was 1, gonna be 2
                if (!selected.$match.$or[0]?.id?.$in) {
                    $match.$or.push(selected.$match.$or[0]); // Length: 2

                    // Moving fields
                    if (selected?.$match?.$or?.[0]?.id?.$exists) {
                        if ($match.$or[1].id) $match.$or[1].id.$exists = true;
                        else $match.$or[1].id = { $exists: true };
                    }

                    if (_nins.length && $match.$or[1]?.id)
                        $match.$or[1].id = { ...$match.$or[1].id, $nin: _nins };
                    else if (_nins.length)
                        $match.$or[1].id = { $nin: _nins };
                }

                // 3) Previous state of $match was "single"
                // Length was 1, still gonna be 1
                else {

                }
            }

            // Remove empty objects and arrays
            if (!_ins.length && $match?.$or?.[0]?.id?.$in)
                delete $match.$or[0].id.$in;
            if (!_nins.length && $match?.$or?.[0]?.id?.$nin)
                delete $match.$or[0].id.$nin;
            if (!_nins.length && $match?.$or?.[1]?.id?.$nin)
                delete $match.$or[1].id.$nin;

            setNinSelections(_nins);
            setInSelections(_ins);

            setSelected({ $match });
            selectionCount($match); // Re-calculate selection count
        }

        else if (type === "all") {
            setNinSelections([]);
            setInSelections([]);

            let $match;

            // If not everything is selected
            if (!checked) {
                // Select all
                $match = { $or: [ { id: { $exists: true } } ] };
            }
            // If already everything is selected
            else {
                // Deselect all
                $match = { $or: [ { id: null } ] };
            }

            setSelected({ $match });
            selectionCount($match); // Re-calculate selection count
        }

        else if (type === "publishable") {
            setNinSelections([]);
            setInSelections([]);

            let $match = {
                $or: [{ published: false, type: { $in: articleTypes } }]
            };

            setSelected({ $match });
            selectionCount($match); // Re-calculate selection count
        }

        else if (type === "published") {
            setNinSelections([]);
            setInSelections([]);

            let $match = {
                $or: [{ published: true }]
            };

            setSelected({ $match });
            selectionCount($match); // Re-calculate selection count
        }
    }

    function isSelected(data) {
        if (inSelections.includes(data.id)) return true;
        if (ninSelections.includes(data.id)) return false;

        // Published check
        if (
            (
                selected.$match?.$or?.[1]?.published === false
                || selected.$match?.$or?.[0]?.published === false
            )
            && articleTypes.includes(data.type)
            && !data.published
        ) return true;

        if (
            (
                selected.$match?.$or?.[1]?.published === true
                || selected.$match?.$or?.[0]?.published === true
            )
            && data.published
        ) return true;

        // Is everything selected
        if (
            selected.$match?.$or?.[0]?.id?.$exists === true ||
            selected.$match?.$or?.[1]?.id?.$exists === true
        ) return true;

        return false;
    }

    // Selected count
    function selectionCount(selected_ = null) {
        fetch(process.env.REACT_APP_API + "/article/selection-details", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                selected: selected_ ? selected_ : selected.$match,
                filter,
            })
        })
        .then(data => data.json())
        .then(data => {
            setSelectedCount(data.count);
            setPublishablesSelected(data?.onlyPublishable);
        });
    }

    function resetSelection() {
        setSelected({ $match: { $or: [{ id: null }] } });
        setPublishablesSelected(false);
        setNinSelections([]);
        setInSelections([]);
        setSelectedCount(0);
    }

    // Download
    function download(
        type,
        ext = "file",
        iteration = 1,
        max = 0
    ) {
        // Download as multiple files
        if (ext === "file") {
            fetch(process.env.REACT_APP_API + "/article/get-contents", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    selected: selected.$match,
                    page: iteration,
                    perPage: 10,
                    filter,
                    max
                })
            })
            .then(data => data.json())
            .then(data => {
                if (!data?.articles?.length) toast.error("Download is failed");
    
                for (let j = 0; j < data.articles.length; j++) {
                    setTimeout(() => {
                        let a = data.articles[j];
    
                        // File Name
                        let filename = a.content.slice(0, 15) + "...";
                                
                        // File Content
                        const htmlStyling = 
                        `<style>`+
                            `body { padding: 10px; }`+
                            `* { font-family: sans-serif; margin: 0; }`+
                            `h1, h2, h3, h4, h5, h6 { margin-top: 15px; }`+
                            `p { margin: 5px 0 10px 10px; }`+
                            `img { width: 60%; }`+
                        `</style>`.replaceAll(" ", "", "\n", "");
    
                        let source;
                        if (type === "txt")
                            source = 'data:text/plain;charset=utf-8,' + encodeURIComponent(convert(a.content));
    
                        else if (type === "html") {
                            source = 'data:text/html;charset=utf-8,' + encodeURIComponent(
                                (a?.image ? `<img src="${a.image}" />` : "") + 
                                htmlStyling + 
                                a.content.replaceAll("<br>", "")
                            );
                        }
    
                        // Download File
                        let fileDownload = document.createElement("a");
                        document.body.appendChild(fileDownload);
                        fileDownload.href = source;
                        fileDownload.download = filename + "." + type;
                        fileDownload.click();
                        document.body.removeChild(fileDownload);
    
                    }, iteration * 1500);
                }
    
                if (data.max > iteration * 10) download(type, ext, iteration + 1);
            });
        }

        // Download as a ZIP file
        else if (ext === "zip") {
            fetch(process.env.REACT_APP_API + "/article/download", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                },
                body: JSON.stringify({
                    selected: selected.$match,
                    filter,
                    type
                })
            })
            .then(async response => {
                if (!response.ok) toast.error("Articles cannot be installed at the moment");
                // let json = await response.json();
                // if (!json?.success) return toast.error(json.message);
                return response.blob();
            })
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'example.zip';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            });
        }
    }

    // Delete Article
    function deleteArticle() {
        fetch(process.env.REACT_APP_API + "/article/delete-articles", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                selected: selected.$match,
                filter,
            })
        })
        .then(data => data.json())
        .then(data => {
            setDeleteModal(false);

            if (data?.success) {
                toast.success("Selected articles successfully deleted");

                // Reset pagination if needed
                if (page * perPage >= articles.max) {
                    setPage(1);

                    // Refetch and reset
                    refetch({ page: 1 }).then(() => resetSelection());
                } else refetch().then(() => resetSelection());
            }
            else if (data?.message) toast.error(data.message);
        })
        .catch(err => {
            console.error(err);
            setDeleteModal(false);
        });
    }

    if (ID) return (<Article ID={ID} refetch={refetch} />)

    return (
        <div id="articles">
            <div className="top">
                <div>
                    <div className="checkbox">
                        <input
                            type="checkbox"
                            checked={articles.max === selectedCount}
                            onChange={() => handleSelect("all", articles.max === selectedCount)}
                        />
                        <p
                            className="clickable"
                            onClick={() => handleSelect("all", articles.max === selectedCount)}
                            style={{ width: 120, marginLeft: 10 }}
                        >
                            <span>Selected</span> &#40;{selectedCount}&#41;
                        </p>
                    </div>

                    <p
                        className="clickable active"
                        onClick={() => handleSelect("publishable")}
                    >
                        Publishable Articles
                    </p>
                    <p
                        className="clickable green"
                        onClick={() => handleSelect("published")}
                    >
                        Published Articles
                    </p>
                </div>
                <div>
                    <div className="pagination">
                        <span>Page: <b>{page}</b></span>
                        <BsChevronLeft
                            color={page > 1 ? "#1c1c1c" : "#c7c7c7"}
                            cursor={page > 1 ? "pointer" : "default"}
                            size={25}
                            onClick={() => {
                                if (page > 1) {
                                    const new_page = Math.max(page - 1, 1);
                                    setPage(new_page);
                                    refetch({ page: new_page });
                                }
                            }}
                        />
                        <select
                            defaultValue={20}
                            value={perPage}
                            onChange={e => {
                                let newVal = parseInt(e.target.value);
                                let new_page = null;

                                setPerPage(newVal);

                                if (page * newVal >= articles.max) {
                                    new_page = Math.max(Math.ceil(articles.max / newVal), 1)
                                    setPage(new_page);
                                }

                                refetch({ page: new_page, perPage: newVal });
                            }}
                        >
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                        <BsChevronRight
                            color={perPage * page < articles.max ? "#1c1c1c" : "#c7c7c7"}
                            cursor={perPage * page < articles.max ? "pointer" : "default"}
                            size={25}
                            onClick={() => {
                                if (perPage * page < articles.max) setPage(page + 1);
                                refetch({ page: page + 1 });
                            }}
                        />
                    </div>
                </div>
                <div className="mobile">
                    <div className="filters">
                        <div className="types">
                            <div className="selection-container">
                                <button onClick={() => setGroupSelection(true)}>
                                    { groupType }
                                    <BsChevronDown className="down-arrow" />
                                </button>
                                <div>
                                    <Selection
                                        items={["All Content", ...Object.keys(groups)]}
                                        click={e => {
                                            setGroupType(e.target.innerText);
                                        }}
                                        visible={groupSelection}
                                        setVisible={setGroupSelection}
                                        customCSS={{ "main": { width: "300px" } }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter">
                            <HiMagnifyingGlass />
                            <input
                                placeholder="Search..."
                                onChange={e => {
                                    let content = e.target.value;

                                    if (content.length > 4) {
                                        setFilter({ 
                                            ...filter,
                                            content
                                        });
                                    }

                                    else if (filter?.content !== undefined) {
                                        setFilter((f) => {
                                            delete f.content;
                                            refetch(f, true);
                                            return f;
                                        });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* ARTICLES */}
            <div className="body">
                { (articles.status === "pending") || (loading) ? (
                    <div className="loader">
                        <img src="/images/loader.svg" alt="Loading" />
                    </div>
                ) : (
                    <>
                    { articles.max === 0 ? (
                        <>
                            { filter?.content?.length ? (
                                <p className="empty">Searched article cannot be found.</p>
                            ) : (
                                <p className="empty">There are no articles, what about <Link to="/dashboard/templates">creating a one</Link>?</p>
                            )}
                        </>
                    ) : (
                        <>
                        {articles.data.map((a, i) => {
                            if (!a?.id) return <></>;

                            let isSelected_ = isSelected(a);
                            let overviewContent = convert(a.content);

                            return (
                                <div key={i} className="article">
                                    <div className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={isSelected_}
                                            onChange={e => handleSelect(
                                                "single",
                                                e.target.checked,
                                                a
                                            )}
                                        />
                                    </div>
                                    <div className="container">
                                        <Link to={"/dashboard/articles/?id=" + a.id}>
                                            <div className="title">
                                                <h3>{templateTitles[a.type]}</h3>

                                                <div className="keywords">
                                                    {a?.keywords?.split(", ").map((keyword, j) => {
                                                        if (!keyword) return <></>
                                                        return (
                                                            <div key={"k-" + j}>
                                                                <span>{keyword}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <div className="content">
                                                <p>{overviewContent}</p>
                                            </div>
                                            <div className="informations">
                                                <div>
                                                    <div className="info">Words:<b>{a.words}</b></div>
                                                    <div className="info">Characters:<b>{a.chars}</b></div>
                                                    <div className="info">Cost:<b>{Math.floor(a?.cost || 0)}</b> <Brand />Credits</div>
                                                    <div className="info">Model:<b>{a?.model || "GPT-3.5"}</b></div>
                                                    { a.type === "article" && <div className="info">Image:<b>{a.image ? "Yes" : "No"}</b></div> }
                                                    { a.published && <div className="info wp"><GrWordpress color="#fff" size={20} /></div> }
                                                </div>
                                                <div className="date">
                                                    <span>{new Date(a.date).toLocaleString()}</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                        </>
                    )}
                    </>
                )}
            </div>
            <div className="bottom">
                <p className="total">Total: <b>{articles.max}</b></p>
                <div>
                    {/* Delete Button */}
                    <button
                        disabled={(selectedCount > 0) ? false : true}
                        onClick={() => setDeleteModal(true)}
                        className="red"
                    >
                        <FaTrashCan />
                        <span>Delete</span>
                    </button>

                    {/* Publish on WordPress Button */}
                    <button
                        disabled={!publishablesSelected || selectedCount === 0}
                        onClick={() => setPublishModal(true)}
                        className="blue"
                    >
                        <GrWordpress />
                        <span>Publish on WordPress Blog</span>
                    </button>

                    {/* Download Button */}
                    <button
                        disabled={(selectedCount > 0) ? false : true}
                        onClick={() => setDownloadModal(true)}
                    >
                        <BsDownload />
                        <span>Download</span>
                    </button>

                    {publishing && <img src="/images/loader.svg" className="loader" alt="Loader" />}
                </div>
            </div>

            {/* Modals */}
            {deleteModal &&
                <ConfirmationModal
                    body={`You're going to delete ${selectedCount} ${selectedCount > 1 ? "articles" : "article"}, are you sure?`}
                    set={setDeleteModal}
                    click={deleteArticle}
                />
            }

            {publishModal &&
                <PublishModal
                    publishFunc={({ status, url, category = undefined }) => {
                        setPublishing(true);

                        let params = {
                            url,
                            status,
                            filter,
                            selected
                        };

                        if (category !== undefined)
                            params["category"] = category;

                        publish(params)
                            .then(resp => {
                                setPublishing(false);

                                if (!resp?.multiple)
                                    toast.success("Article is successfully published");

                                refetch().then(() => { resetSelection() });
                            })
                            .catch(err => {
                                setPublishing(false);
                                toast.error(err);
                            });
                    }}
                    set={setPublishModal}
                    length={selectedCount}
                />
            }

            {downloadModal &&
                <DownloadModal
                    click={(type, ext) => download(type, ext)}
                    set={setDownloadModal}
                />
            }
        </div>
    )
}