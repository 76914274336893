import { useEffect, useState } from 'react';
import './plain.scss';

export default function PlainEditor({ content, image }) {

    const [text, setText] = useState(content);

    useEffect(() => {
        if (image) setText(`<img src="${image}" /><br/>${content}`);
        else setText(content)
    }, [image, content]);

    return (
        <div className="plain-container">
            <div
                className="content"
                dangerouslySetInnerHTML={{__html: text}}
            >
            </div>
        </div>
    )
}