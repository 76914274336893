import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import { LiaSaveSolid } from "react-icons/lia";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './editor.scss';

function SaveButton({saved, save}) {
    return (
        <button className="save-button" disabled={saved ? true : false} onClick={save}>
            <LiaSaveSolid />
        </button>
    )
}

export default function CustomEditor({
    loading,
    content,
    setContent,
    setPlainContent,
    image = null,
    save=() => {},
}) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [saved, setSaved] = useState(true);

    useEffect(() => {
        if (image) content = `<img src="${image}" /><br/>${content}`;
        setEditorState(
            EditorState.createWithContent(
                customContentStateConverter(
                    ContentState.createFromBlockArray(
                        convertFromHTML(content)
                    )
                )
            )
        );
    }, [loading]);

    function onEditorStateChange(state) {
        setEditorState(state);
        setContent(draftToHtml(convertToRaw(state.getCurrentContent())));
        setPlainContent(state.getCurrentContent().getPlainText());
        setSaved(false);
    }

    function customContentStateConverter(contentState) {
        const newBlockMap = contentState.getBlockMap().map((block) => {
            const entityKey = block.getEntityAt(0);
            if (entityKey !== null) {
                const entityBlock = contentState.getEntity(entityKey);
                const entityType = entityBlock.getType();
                switch (entityType) {
                    case 'IMAGE': {
                        const newBlock = block.merge({
                            type: 'atomic',
                            text: 'img',
                        });
                        return newBlock;
                    }
                    default:
                        return block;
                }
            }
            return block;
        });
        const newContentState = contentState.set('blockMap', newBlockMap);
        return newContentState;
    }

    return (
        <div className="editor">
            <Editor
                toolbarCustomButtons={[<SaveButton saved={saved} save={save} />]}
                wrapperClassName="editor-wrapper"
                editorClassName="editor-content"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        </div>
    )
}