import { createSlice } from "@reduxjs/toolkit";

export const lengthsSlice = createSlice({
    name: "lengths",
    initialState: {
        "article": {
            short: 4,
            medium: 8,
            long: 12,
            extraLong: 20
        },
        "faq": {
            short: 10,
            medium: 20,
            long: 30
        },
    },
    reducers: {
        setLengths: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setLengths } = lengthsSlice.actions;
export default lengthsSlice.reducer;