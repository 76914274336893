import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convert } from 'html-to-text';
import { BsChevronDown, BsChevronLeft, BsDownload } from 'react-icons/bs';
import { HiLanguage } from "react-icons/hi2";
import { GrWordpress } from "react-icons/gr";
import { publish } from '../../../helpers/publish';
import { secondsToMMSS } from '../../../helpers/time';
import { languages, langCodes } from '../../../lib/languages';
import { setUser } from '../../../store/reducers/userReducer';
import { setArticles } from '../../../store/reducers/articlesReducer';
import CustomEditor from '../../../components/editor';
import PlainEditor from '../../../components/plain';
import Selection from '../../../components/selection';
import PublishModal from '../../../components/publish-modal';
import DownloadModal from '../../../components/download-modal';
import socket from '../../../socket';
import './template.scss';

const randomTips = [
    "Be sure your inputs are in the same language as your selected output language.",
    "Avoid generating content that might violate privacy or promote harmful behaviors.",
    "Give the AI as much information as possible about the topic.",
    "You can always want credit refund for wrongly created articles.",
    "For any problem, you can always contact with us via Support page.",
    "Your bug reports/feature requests will be rewarded, just open a ticket!"
]

const plainRenderTypes = [
    "enhanced-article",
    "review",
    "comparison",
    "article-2",
]

export default function Template({
    type: articleType,
    title,
    inputs,
    descriptions = {},
}) {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const lengths = useSelector(state => state.lengths);

    const [datas, setDatas] = useState({
        faq: true,
        image: true,
        model: "GPT-3.5",
        length_type: inputs.includes("length-2") ? "custom" : null,
        multiple_title: inputs?.includes("multiple-title")
    });
    const [content, setContent] = useState("");
    const [articleID, setArticleID] = useState(null);
    const [words, setWords] = useState(0);
    const [plainContent, setPlainContent] = useState("");
    const [published, setPublished] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [renderType, setRenderType] = useState("editor"); // editor | plain
    const [randomTipIndex, setRandomTipIndex] = useState(0);
    const [parseType, setParseType] = useState(
        descriptions?.["keywords"]?.["single"]
            ? "comma"
            : (localStorage.getItem("parse-type") || "comma")
    );

    // Modals
    const [publishModal, setPublishModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    
    useEffect(() => {
        if (user) {
            // Close previous listeners if exist
            socket.off(`generate-listener`);

            // Socket listeners
            socket.emit("acknowledge", {...user, access_token: localStorage.getItem("login_token")});

            socket.on(`generate-listener`, data => {
                if (data?.status === "pending") {
                    setLoading(true);
                    setSeconds(data.timer);
                }

                else if (data?.status === "success") {
                    toast.success("Content successfully generated");
                    setPlainContent(convert(data.content));
                    setContent(data.content);
                    setArticleID(data.id);
                    setWords(data.words);
                    setPublished(false);
                    setLoading(false);
                    setSeconds(0);

                    // Change render type
                    if (plainRenderTypes.includes(articleType)) setRenderType("plain");

                    if (data?.image_error) toast.warning("Image cannot be created due to: '"+(data.image_error)+"'");

                    // Update credits
                    dispatch(setUser({...user, credits: data.credits}));

                    // Update Article Status
                    dispatch(setArticles({
                        data: [],
                        status: "fetched",
                        max: 0
                    }));
                }
                
                else if (data?.message && data?.status === "failed") {
                    toast.error(data.message);
                    setLoading(false);
                    setSeconds(0);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        const datas_obj = {...datas};

        if (!datas_obj?.["language"] || !datas_obj?.["length"]) {
            // Stored language
            if (localStorage.getItem("language"))
                datas_obj["language"] = localStorage.getItem("language");

            // Set article length
            if (Object.keys(lengths).includes(articleType))
                datas_obj["length"] = lengths[articleType][localStorage.getItem("article-length") || "short"];
        }

        // Manage multiple line separation and comma separation
        let raw_keywords = datas_obj?.keywords || "";
        let raw_titles = datas_obj?.title || "";

        if (inputs.includes("keywords") && parseType === "comma" && raw_keywords)
            raw_keywords = raw_keywords.replace(/(^[ \t]*\n)/gm, "").replaceAll("\n", ", ");

        else if (inputs.includes("keywords") && parseType === "new line" && raw_keywords)
            raw_keywords = raw_keywords.replaceAll(", ", "\n").replace(/(^[ \t]*\n)/gm, "");

        if (inputs.includes("title") && parseType === "comma" && raw_titles)
            raw_titles = raw_titles.replace(/(^[ \t]*\n)/gm, "").replaceAll("\n", ", ");

        else if (inputs.includes("title") && parseType === "new line" && raw_titles)
            raw_titles = raw_titles.replaceAll(", ", "\n").replace(/(^[ \t]*\n)/gm, "");

        if (raw_keywords) datas_obj["keywords"] = raw_keywords;
        if (raw_titles) datas_obj["title"] = raw_titles;

        setDatas(datas_obj);

    }, [parseType]);

    // Selections
    const [langSelection, setLangSelection] = useState(false);

    /* Functions */
    // Generate
    function generate() {
        if (!datas?.language) {
            toast.error("Please choose a language");
            return;
        }

        const contentTypes = ["spinner", "translate"];
        const explainTypes = ["marketing"];
        const titleTypes   = ["title-spinner", "story", "comparison", "article-2"];

        const nonKeywordTypes = [...contentTypes, ...explainTypes, ...titleTypes];

        // Keyword is not required but some other necessary fields are empty
        if (
            (!datas?.content && contentTypes.includes(articleType)) ||
            (!datas?.explain && explainTypes.includes(articleType)) ||
            (!datas?.title && titleTypes.includes(articleType))
        ) {
            toast.error("Please fill all the necessary areas.");
            return;
        }

        // Keyword required but isn't received
        if (!datas?.keywords && !nonKeywordTypes.includes(articleType)) {
            toast.error("Please enter at least one keyword");
            return;
        }

        // Langauge code
        if (datas?.image) {
            datas.languageCode = langCodes?.[datas.language] || "en-US";
        }

        setLoading(true);
        setSeconds(0);
        setRandomTipIndex(Math.floor(Math.random() * (randomTips.length)));

        socket.emit("generate", {
            access_token: localStorage.getItem("login_token"),
            articleType,
            datas
        });
    }

    function download(type) {
        // File Name
        let filename;
        try {
            if (datas?.title) filename = datas.title;
            else if (datas?.keywords) filename = datas.keywords.split(",")[0];
            else filename = articleType;
        } catch(e) { filename = "content"; }

        // File Content
        let source;
        const htmlStyling =
        `<style>` +
            `body { padding: 10px; }`+
            `* { font-family: sans-serif; margin: 0; }`+
            `h1, h2, h3, h4, h5, h6 { margin-top: 15px; }`+
            `p { margin: 5px 0 10px 10px; }`+
            `img { width: 60%; }`+
        `</style>`
        .replaceAll("\n", "");

        if (type === "txt") source = 'data:text/plain;charset=utf-8,' + encodeURIComponent(plainContent || convert(content));
        else if (type === "html") source = 'data:text/html;charset=utf-8,' + encodeURIComponent(htmlStyling + content.replaceAll("<br>", ""));

        // Download File
        let fileDownload = document.createElement("a");
        document.body.appendChild(fileDownload);
        fileDownload.href = source;
        fileDownload.download = filename + "." + type;
        fileDownload.click();
        document.body.removeChild(fileDownload);
    }

    // Save articles
    function save() {
        if (!plainContent?.trim()) {
            toast.error("Your article must contain content");
            return;
        }

        fetch(process.env.REACT_APP_API + "/article/save", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                content,
                article_id: articleID
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.success) {
                toast.success("Article is successfully saved");
            } else if (data?.message) {
                toast.error(data.message);
            }
        });
    }

    return (
        <div className="generate-container">
            { loading ? (
                <div className="loading">
                    <small><b>Tip:</b> {randomTips[randomTipIndex]}</small>
                    <p>Hold on! Your <b>content</b> is being generated</p>
                    <p className="time">{secondsToMMSS(seconds)}</p>
                    <img src="/images/loader.svg" />
                </div>
            ) : (
                <div className="input" id="input-field">
                    <h2>
                        {title}
                        <Link to="/dashboard/templates">
                            <BsChevronLeft />
                            <span>Go back</span>
                        </Link>
                    </h2>

                    { inputs.includes("keywords") &&
                        <div className="text">
                            <span>Keywords</span>
                            <small>
                                {descriptions?.["keywords"]?.["desc"] || <>The first keyword is the <span>primary</span> one. Seperate keywords with comma.</>}
                            </small>

                            { !descriptions?.["keywords"]?.["single"] && (
                                <span
                                    className="button"
                                    onClick={() => setParseType(type => {
                                        type = type === "comma" ? "new line" : "comma";
                                        localStorage.setItem("parse-type", type);
                                        return type;
                                    })}
                                >
                                    Seperate with {parseType}
                                </span>
                            )}

                            { parseType === "comma" ? (
                                <input
                                    placeholder={descriptions?.["keywords"]?.["example"] || "how to break bad habits, breaking habits..."}
                                    onChange={e => setDatas({...datas, keywords: e.target.value})}
                                    value={datas?.keywords || ""}
                                    maxLength={100}
                                />
                            ) : (
                                <textarea
                                    placeholder={`how to catch fish, catching a fish, fish
driving a car, how to drive a car, cars
medical help, how can I get medical help
being clumsy in a nutshell`}
                                    onChange={e => setDatas({...datas, keywords: e.target.value, parseType})}
                                    value={datas?.keywords || ""}
                                ></textarea>
                            )}
                        </div>
                    }
                    { inputs.includes("language") &&
                        <div className="selection-container">
                            <span>{articleType === "translate" ? "Translate to:" : "Content Language"}</span>
                            <small>We suggest you be sure language of inputs is same as "Content Language"</small>
                            <button onClick={
                                () => {
                                    setLangSelection(true);
                                }}
                            >
                                <HiLanguage />
                                {datas?.language || "Select a language"}
                                <BsChevronDown className="down-arrow" />
                            </button>
                            <div>
                                <Selection
                                    items={languages}
                                    click={(e) => {
                                        setDatas({...datas, language: e.target.innerText});
                                        localStorage.setItem("language", e.target.innerText);
                                    }}
                                    visible={langSelection}
                                    setVisible={setLangSelection}
                                />
                            </div>
                        </div>
                    }
                    { inputs.includes("content") &&
                        <div className="text">
                            <span>Content</span>
                            { descriptions?.["content"] && <small>{descriptions?.["content"]?.["desc"]}</small> }
                            <textarea
                                placeholder={descriptions?.["content"]?.["example"] || "Quitting bad habits demands unwavering determination and self-awareness. It is a transformative journey where you challenge the status quo of your routines, conquering the allure of instant gratification for the promise of long-term well-being..."}
                                onChange={e => setDatas({...datas, content: e.target.value})}
                                defaultValue={datas?.content || ""}
                            ></textarea>
                            <small className="content-words">{datas?.content?.split(" ")?.length || 0} Words</small>
                        </div>
                    }
                    { inputs.includes("explain") &&
                        <div className="text">
                            <span>Explain</span>
                            <small>{descriptions["explain"]["desc"]}</small>
                            <textarea
                                placeholder={descriptions["explain"]["example"]}
                                onChange={e => setDatas({...datas, explain: e.target.value})}
                                defaultValue={datas?.explain || ""}
                                maxLength={250}
                            ></textarea>
                            <small className="content-words">{datas?.explain?.split(" ")?.length || 0} Words</small>
                        </div>
                    }
                    { inputs.includes("length") &&
                        <div className="length">
                            <span>Content Length</span><br />
                            <small>
                                { datas["length"] == lengths[articleType]?.short
                                    ? `Article will contain 600-800 words`
                                    : datas["length"] == lengths[articleType]?.medium
                                        ? `Article will contain 800-1500 words`
                                        : datas["length"] == lengths[articleType]?.long
                                            ? `Article will contain 1500-3300 words`
                                            : datas["length"] == lengths[articleType]?.extraLong
                                                ? `Article will contain 2500-5000 words`
                                                : `Article will contain 600-800 words`
                                }
                            </small>
                            <div>
                                <button
                                    className={datas["length"] == lengths[articleType]?.short ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, length: lengths[articleType]?.short});
                                        localStorage.setItem("article-length", "short");
                                    }}
                                >Short</button>
                                <button
                                    className={datas["length"] == lengths[articleType]?.medium ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, length: lengths[articleType]?.medium});
                                        localStorage.setItem("article-length", "medium");
                                    }}
                                >Medium</button>
                                <button
                                    className={datas["length"] == lengths[articleType]?.long ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, length: lengths[articleType]?.long});
                                        localStorage.setItem("article-length", "long");
                                    }}
                                >Long</button>
                                <button
                                    className={datas["length"] == lengths[articleType]?.extraLong ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, length: lengths[articleType]?.extraLong});
                                        localStorage.setItem("article-length", "extraLong");
                                    }}
                                >Extra</button>
                            </div>
                        </div>
                    }
                    { inputs.includes("length-2") &&
                        <div className="length-2 text">
                            <span>Content length</span>
                            <small>{ descriptions?.["length-2"] || "Enter your content length" }</small>
                            <input
                                type="number"
                                className="number-input"
                                placeholder="10"
                                onChange={e => setDatas({...datas, length: parseInt(e.target.value || 0), length_type: "custom"})}
                                defaultValue={datas?.["length"] || undefined}
                            /> 
                        </div>
                    }
                    { inputs.includes("model") && 
                        <div className="length">
                            <span>AI Model</span><br />
                            <small>
                                { datas?.["model"] === "GPT-3.5" 
                                    ? "GPT-3.5 is a faster AI engine"
                                    : "GPT-4 is a slower but a better AI engine"
                                }
                                { datas?.["model"] === "GPT-4" && 
                                <>
                                    <br />
                                    <span>GPT-4 will cost 1.3x more</span>
                                </>
                                }
                            </small>
                            <div>
                                <button
                                    className={datas["model"] === "GPT-3.5" ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, model: "GPT-3.5"});
                                    }}
                                >GPT-3.5</button>
                                <button
                                    className={datas["model"] === "GPT-4" ? "active" : undefined}
                                    onClick={() => {
                                        setDatas({...datas, model: "GPT-4"});
                                    }}
                                >GPT-4</button>
                            </div>
                        </div>
                    }
                    { inputs.includes("title") &&
                        <div className="text">
                            <span>Title{inputs.includes("multiple-title") && "s"} { articleType === "article" && <small>(Optional)</small> }</span>
                            { descriptions?.["title"] && <small>{descriptions?.["title"]?.["desc"]}</small> }
                            { inputs.includes("multiple-title") && (
                                <span
                                    className="button"
                                    onClick={() => setParseType(type => {
                                        type = type === "comma" ? "new line" : "comma";
                                        localStorage.setItem("parse-type", type);
                                        return type;
                                    })}
                                >
                                    Seperate with {parseType}
                                </span>
                            )}
                            
                            { (parseType === "comma" || !inputs.includes("multiple-title")) ? (
                                <input
                                    placeholder={descriptions?.["title"]?.["example"] || "A Comprehensive Guide for Breaking Bad Habits"}
                                    onChange={e => setDatas({...datas, title: e.target.value})}
                                    value={datas?.title || ""}
                                    maxLength={250}
                                />
                            ) : (
                                <textarea
                                    placeholder={descriptions?.["title"]?.["example"] ||
                                        `Tips for Catching Fishes
How to Drive a Car? Driving Car Essentials
Fishing 101: How to Catch Fish?`
                                    }
                                    onChange={e => setDatas({...datas, title: e.target.value, parseType})}
                                    value={datas?.title || ""}
                                ></textarea>
                            )}
                        </div>
                    }
                    { inputs.includes("subtitles") &&
                        <div className="text">
                            <span>Subtitles { articleType === "article" && <small>(Optional)</small> }</span>
                            <small>Each subtitle will has its unique content. Seperate subtitles with comma.</small>
                            <input
                                placeholder="How to Quit Bad Habits, Pros and Cons of a Bad Habit..."
                                onChange={e => setDatas({...datas, subtitles: e.target.value})}
                                defaultValue={datas?.subtitles || ""}
                                maxLength={500}
                            />
                        </div>
                    }
                    { inputs.includes("image") &&
                    <>
                        <span>Image</span>
                        <small>Photos are provided by <a href="https://www.pexels.com">Pexels</a></small>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="include-image"
                                onChange={e => setDatas({...datas, image: e.target.checked})}
                                checked={datas?.image}
                            />
                            <label htmlFor="include-image">Find an image related with article <small>(Free)</small></label>
                        </div>
                    </>
                    }
                    { inputs.includes("faq") &&
                    <>
                        <span>FAQ</span>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="include-faq"
                                onChange={e => setDatas({...datas, faq: e.target.checked})}
                                checked={datas?.faq}
                            />
                            <label htmlFor="include-faq">Add a FAQ (Frequently Asked Questions) section in article <small>(Free)</small></label>
                        </div>
                    </>
                    }
                    { /*inputs.includes("video") &&
                    <>
                        <span>Video</span>
                        <small>Do you want to include a Youtube video related to your article keyword?</small>
                        <div className="checkbox">
                            <input
                                type="checkbox"
                                id="include-video"
                                onChange={e => setDatas({...datas, video: e.target.checked})}
                                checked={datas?.video}
                            />
                            <label htmlFor="include-video">Find a Youtube video related with article <small>(+100 Credits)</small></label>
                        </div>
                    </>
                    */}
                </div>
            )}
            <div className="output">
                { renderType === "editor" ? (
                    <CustomEditor
                        loading={loading}
                        content={content}
                        setContent={setContent}
                        setPlainContent={setPlainContent}
                        save={save}
                    />
                ) : (
                    <PlainEditor content={content} />
                )}
            </div>
            <div className="buttons">
                <div>
                    { !loading && 
                        <button 
                            onClick={generate}
                        >
                            Generate
                        </button>
                    }
                </div>
                <div>
                    { words > 0 && 
                        <span className="data">
                            <b>{words}</b> Words
                        </span>
                    }
                    { publishing ? (
                        <img src="/images/loader.svg" className="loader" />
                    ) : (
                        <>
                        {(inputs.includes("website") && !published && !publishing) && 
                            <button
                                disabled={(content) ? false : true}
                                className="blue"
                                onClick={() => setPublishModal(true)}
                            >
                                <GrWordpress />
                                <span>Publish on WordPress Blog</span>
                            </button>
                        }
                        </>
                    )}
                    <button onClick={() => setDownloadModal(true)}>
                        <BsDownload />
                        <span>Download</span>
                    </button>
                </div>
            </div>

            {/* Modals */}
            { publishModal &&
                <PublishModal
                    publishFunc={({status, url}) => {
                        setPublishing(true);

                        publish({
                            status,
                            url,
                            selected: [articleID]
                        })
                            .then(resp => {
                                if (!resp?.multiple) {
                                    toast.success("Article is successfully published");
                                    setPublished(true);
                                    setPublishing(false);
                                }
                            })
                            .catch((err) => {
                                setPublishing(false);
                                toast.error(err);
                            });
                    }}
                    set={setPublishModal}
                    length={1}
                />
            }

            {downloadModal &&
                <DownloadModal
                    click={type => download(type)}
                    set={setDownloadModal}
                />
            }
        </div>
    )
}