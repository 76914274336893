import { Link } from 'react-router-dom';
import './nav.scss';

export default function Nav() {
    return (
        <nav id="home-nav">
            <h2>FAST & AFFORDABLE<br />AI ARTICLE GENERATOR</h2>
            <p>Create hundreds of unique, SEO-friendly articles in seconds</p>
            <Link to="/login">Start for Free</Link>
            <img src="/images/vectors/wave-3.svg" className="wave" />
        </nav>
    )
}