import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Credits from "../credits";
import { NumberFormatter } from "../../helpers/number";

import './dashboard-header.scss';

const titleAndPages = {
    "/templates": "Generate",
    "/articles": "Articles",
    "/settings": "Settings",
    "/websites": "Websites",
    "/credits": "Credits",
    "/bulk": "Bulk Generate",
    "/tickets": "Ticket",
    "/affiliate": "Affiliate",
    "/": "Overview"
};

export default function DashboardHeader() {

    const location = useLocation();
    const user = useSelector(state => state.user);

    const [title, setTitle] = useState("Overview");

    useEffect(() => {
        let path = location.pathname;

        for (let i = 0; i < Object.keys(titleAndPages).length; i++) {
            if (path.includes(Object.keys(titleAndPages)[i])) {
                setTitle(Object.values(titleAndPages)[i]);
                break;
            }
        }

    }, [location.pathname]);

    if (!user) return <></>
    return (
        <div id="dashboard-header">
            <div className="page">
                <h2>{title}</h2>
            </div>
            <div className="info">
                <Link to="/dashboard/credits"><Credits amount={NumberFormatter(user.credits)} /></Link>
                <div className="welcome">{user.name}</div>
            </div>
        </div>
    )
}