import { useEffect, useState } from "react";
import Brand from "../../../components/brand";
import './pricing.scss';

export default function Pricing({ showDesc = false }) {

    // Calculator variables
    const [rate, setRate] = useState(0);
    const [money, setMoney] = useState(0);
    const [credit, setCredit] = useState(1000);
    const [word, setWord] = useState(1000);

    useEffect(() => {
        document.title = "Pricing - DURBUN";
        
        fetch(process.env.REACT_APP_API + "/credits/credit-price", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            let price = data?.price || 0.07;
            setRate(price);
            setMoney(credit / 1000 * price);
        });
    }, []);

    function handleChange(e, type) {
        let val = parseFloat(e.target.value);
        let newCredit, newMoney;

        if (val) {
            switch (type) {
                case "money":
                    newCredit = (val / rate) * 1000;
                    newMoney = val;
                    break;

                case "word":
                    newCredit = val;
                    newMoney = (val * rate) / 1000;
                    break;
            }
        }

        setMoney(Math.round(newMoney * 100) / 100);
        setCredit(Math.floor(newCredit));
        setWord(Math.floor(newCredit));
    }

    return (
        <div id="pricing">
            <h2>Pricing Calculator</h2>
            <p>Every <Brand /> credit is equivalent to a word</p>

            <div className="calculator">
                <section>
                    <h4>MONEY</h4>
                    <p>1000 Credits = {rate}$</p>
                    <div className="input">
                        <input type="number" value={(money || 0)} onChange={e => handleChange(e, "money")} />
                        <span>USD</span>
                    </div>
                </section>
                <section>
                    <h4>WORDS</h4>
                    <p>1$ = {Math.floor(1 / rate * 1000)} Words</p>
                    <div className="input">
                        <input type="number" value={(word || 0)} onChange={e => handleChange(e, "word")} />
                        <span>Words</span>
                    </div>
                </section>
            </div>
            <div className="result">
                <p>You'll get <b>{credit || 0}</b> <Brand /> credits for <b>{(money || 0).toFixed(2)}$</b>, you'll be allowed to create <b>{word || 0}</b> words of content.</p>
            </div>

            { showDesc && (
                <div className="description">
                    <ul>
                        <li>You can buy credits from user dashboard.</li>
                        <li>For every word that you get as output, same amount of credit gets consumed.</li>
                        <li>In case of wastage of your credits, reach our support team and we'll refund your consumed credits.</li>
                    </ul>
                </div>
            )}

            <img src="/images/vectors/wavy-stroke.svg" className="wave" />
        </div>
    )
}