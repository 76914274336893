export const languages = [
    'English',
    'Turkish (Türkçe)',
    'Abkhazian',
    'Afar',
    'Afrikaans',
    'Akan',
    'Albanian',
    'Amharic',
    'Arabic',
    'Aragonese',
    'Armenian',
    'Assamese',
    'Avaric',
    'Avestan',
    'Aymara',
    'Azerbaijani',
    'Bambara',
    'Bashkir',
    'Basque',
    'Belarusian',
    'Bengali',
    'Bihari',
    'Bislama',
    'Bosnian',
    'Breton',
    'Bulgarian',
    'Burmese',
    'Catalan',
    'Central Khmer',
    'Chamorro',
    'Chechen',
    'Chichewa',
    'Chinese',
    'Church Slavonic',
    'Chuvash',
    'Cornish',
    'Corsican',
    'Cree',
    'Croatian',
    'Czech',
    'Danish',
    'Divehi',
    'Dutch',
    'Dzongkha',
    'Esperanto',
    'Estonian',
    'Ewe',
    'Faroese',
    'Fijian',
    'Finnish',
    'French',
    'Fulah',
    'Gaelic, Scottish Gaelic',
    'Galician',
    'Ganda',
    'Georgian',
    'German',
    'Gikuyu, Kikuyu',
    'Greek',
    'Greenlandic, Kalaallisut',
    'Guarani',
    'Gujarati',
    'Haitian, Haitian Creole',
    'Hausa',
    'Hebrew',
    'Herero',
    'Hindi',
    'Hiri Motu',
    'Hungarian',
    'Icelandic',
    'Ido',
    'Igbo',
    'Indonesian',
    'Interlingue',
    'Inuktitut',
    'Inupiaq',
    'Irish',
    'Italian',
    'Japanese',
    'Javanese',
    'Kannada',
    'Kanuri',
    'Kashmiri',
    'Kazakh',
    'Kinyarwanda',
    'Komi',
    'Kongo',
    'Korean',
    'Kwanyama',
    'Kurdish',
    'Kyrgyz',
    'Lao',
    'Latin',
    'Latvian',
    'Letzeburgesch',
    'Limburgish',
    'Lingala',
    'Lithuanian',
    'Luba-Katanga',
    'Macedonian',
    'Malagasy',
    'Malay',
    'Malayalam',
    'Maltese',
    'Manx',
    'Maori',
    'Marathi',
    'Marshallese',
    'Romanian',
    'Mongolian',
    'Nauru',
    'Navajo, Navaho',
    'Northern Ndebele',
    'Ndonga',
    'Nepali',
    'Northern Sami',
    'Norwegian',
    'Norwegian Bokmål',
    'Norwegian Nynorsk',
    'Nuosu',
    'Occitan',
    'Ojibwa',
    'Oriya',
    'Oromo',
    'Ossetian, Ossetic',
    'Pali',
    'Panjabi, Punjabi',
    'Pashto, Pushto',
    'Persian',
    'Polish',
    'Portuguese',
    'Quechua',
    'Romansh',
    'Rundi',
    'Russian',
    'Samoan',
    'Sango',
    'Sanskrit',
    'Sardinian',
    'Serbian',
    'Shona',
    'Sindhi',
    'Sinhala, Sinhalese',
    'Slovak',
    'Slovenian',
    'Somali',
    'Sotho, Southern',
    'South Ndebele',
    'Spanish',
    'Sundanese',
    'Swahili',
    'Swati',
    'Swedish',
    'Tagalog',
    'Tahitian',
    'Tajik',
    'Tamil',
    'Tatar',
    'Telugu',
    'Thai',
    'Tibetan',
    'Tigrinya',
    'Tonga',
    'Tsonga',
    'Tswana',
    'Turkmen',
    'Twi',
    'Uighur, Uyghur',
    'Ukrainian',
    'Urdu',
    'Uzbek',
    'Venda',
    'Vietnamese',
    'Volapük',
    'Walloon',
    'Welsh',
    'Western Frisian',
    'Wolof',
    'Xhosa',
    'Yiddish',
    'Yoruba',
    'Zhuang, Chuang',
    'Zulu'
]

export const langCodes = {
    "English": "en-US",
    "Portuguese": "pt-BR",
    "Spanish": "es-ES",
    "Catalan": "ca-ES",
    "German": "de-DE",
    "Italian": "it-IT",
    "French": "fr-FR",
    "Swedish": "sv-SE",
    "Indonesian": "id-ID",
    "Polish": "pl-PL",
    "Japanese": "ja-JP",
    "Chinese": "zh-CN",
    "Korean": "ko-KR",
    "Thai": "th-TH",
    "Dutch": "nl-NL",
    "Hungarian": "hu-HU",
    "Vietnamese": "vi-VN",
    "Czech": "cs-CZ",
    "Danish": "da-DK",
    "Finnish": "fi-FI",
    "Ukrainian": "uk-UA",
    "Greek": "el-GR",
    "Romanian": "ro-RO",
    "Norwegian": "nb-NO",
    "Norwegian Bokmål": "nb-NO",
    "Norwegian Nynorsk": "nb-NO",
    "Slovak": "sk-SK",
    "Turkish (Türkçe)": "tr-TR",
    "Russian": "ru-RU"
}