/**
 * Turns seconds into mm:ss form
 */
export function secondsToMMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s}

/**
 * Date to DD/MM/YYYY HH:MM
 */
export function fullDate(inputDate) {
    // Extract date components
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = inputDate.getFullYear();
    const hours = String(inputDate.getHours()).padStart(2, '0');
    const minutes = String(inputDate.getMinutes()).padStart(2, '0');

    // Create formatted date string
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
}