import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FiCheckCircle, FiXOctagon } from "react-icons/fi";
import './confirm.scss';

export default function Confirm() {

    const [queryParameters] = useSearchParams()

    const [confirmed, setConfirmed] = useState(undefined);

    let id = queryParameters.get("id");

    useEffect(() => {
        if (id) {
            fetch(process.env.REACT_APP_API + "/auth/confirm", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: id
                })
            })
            .then(data => data.json())
            .then(data => {
                if (data?.success) {
                    setConfirmed(true);
                    setTimeout(() => {
                        window.location.href = "/login";
                    }, 3000);
                }
                else {
                    setConfirmed(false);
                    if (data?.message) toast.error(data.message);
                }
            })
        } else setConfirmed(false);
    }, [id]);
    
    return (
        <div id="confirm">
            { confirmed === true && (
                <>
                    <FiCheckCircle size={200} color="#00ac52" />
                    <h1>Your account has been successfully confirmed.</h1>
                    <p>You're being redirected to login page, please wait</p>
                </>
            )}

            { confirmed === false && (
                <>
                    <FiXOctagon size={200} color="red" />
                    <h1>Your account cannot be confirmed.</h1>
                </>
            )}
        </div>
    )
}