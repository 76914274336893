import { Link } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { BsCheck2Circle } from 'react-icons/bs';
import WarnBox from '../boxes/warnbox';
import './status.scss';

export default function PublishStatusModal({
    publishProgress = null,
    publishResult = "started",
    set
}) {

    function close(e) {
        if (!e.target.closest("#publish-status-modal .content")) set(false);
    }

    return (
        <div id="publish-status-modal" onClick={close}>
            <div className="content">
                <span onClick={() => set(false)} className="close"><FiX /></span>
                { !publishResult && (
                    <>
                    <img src="/images/loader.svg" className="loader" alt="Loader" />
                    <p>Your articles are uploading.</p>
                    { publishProgress && <span className="progress"><b>{publishProgress}</b> articles are published</span> }
                    </>
                )}
                { publishResult?.message && (
                    <>
                    <p><b>Error: </b>{publishResult.message}</p>
                    {publishResult?.article_id && (
                        <Link
                            to={`/dashboard/articles/?id=${publishResult.article_id}`}
                            onClick={() => set(false)}
                        >
                            Go to article &quot;{publishResult.article_id}&quot;
                        </Link>
                    )}
                    </>
                )}
                { publishResult?.success && (
                    <>
                    <BsCheck2Circle color="#00ac52" size={100} />
                    <p className="success-text">Your articles are successfully uploaded.</p>

                    {publishResult?.titleFailedArticles?.length > 0 && (
                        <WarnBox>
                            { publishResult.titleFailedArticles.length } article cannot be uploaded because they don't have a title.
                        </WarnBox>
                    )}
                    </>
                )}
            </div>
        </div>
    )
}