import { createSlice } from "@reduxjs/toolkit";

export const templateSlice = createSlice({
    name: "template",
    initialState: {
        "article": "SEO-Friendly Article",
        "article-2": "Advanced SEO-Friendly Article",
        "bulk": "Bulk Article Generation",
        "keyword": "Keyword Generator",
        "fast": "Fast Article",
        "intro": "Article Introduction",
        "conclusion": "Article Conclusion",
        "faq": "FAQ",
        "spinner": "Spinner",
        "translate": "Translate",
        "comparison": "Comparison",
        "title": "Title Generator",
        "title-spinner": "Title Spinner",
        "meta": "Meta Description",
        "marketing": "Marketing Text",
        "enhanced-article": "Enhanced Article",
        "book": "Book Summary",
        "review": "Review",
        "story": "Story",
    },
    reducers: {
        setTemplate: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setTemplate } = templateSlice.actions;
export default templateSlice.reducer;