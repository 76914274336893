import { useEffect } from 'react';
import Brand from '../../../components/brand';
import './how-to-use.scss';

export default function HowToUse() {

    useEffect(() => {
        document.title = "How to Use - DURBUN";
    }, []);

    return (
        <div id="how-to-use">
            <h2>How to Use?</h2>

            <div>
                <div className="content">
                    <span className="num">1</span>
                    <h3>Buy <Brand /> credits</h3>
                    <p>To generate articles, you need <Brand /> credits, the credits will be used proportionally to the words in the article. If you're a new user, you can get your 5000 credits for free.</p>
                </div>

                <div className="content">
                    <span className="num">2</span>
                    <h3>Enter Keywords</h3>
                    <p>Explain what your article will be like with simple keywords. The keywords will determine how your article is going to seem like so choose them carefully.</p>
                </div>

                <div className="content">
                    <span className="num">3</span>
                    <h3>Enter Article Length</h3>
                    <p>As the last step, you have to choose the length of your article: Short, Medium, or Long. While short articles are about 1000 words, long articles can be up to 6000 words!</p>
                </div>
            </div>

            <div className="last">
                <div className="content">
                    <span className="num">4</span>
                    <h3>And it's done!</h3>
                    <p>After generating your article, now you can publish your article with our WordPress auto-posting feature or save it to your device. <br /> We'll store your generated articles in case you want to download them again.</p>
                </div>
            </div>
        </div>
    )
}