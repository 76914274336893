import { FiX } from 'react-icons/fi';
import './confirmation-modal.scss';

export default function ConfirmationModal({
    body,
    set,
    click,
}) {

    function close(e) {
        if (!e.target.closest("#confirmation-modal .content")) set(false);
    }

    return (
        <div id="confirmation-modal" onClick={close}>
            <div className="content">
                <div className="top">
                    <h4>Confirm</h4>
                    <FiX onClick={() => set(false)} />
                </div>
                <div className="body">
                    <p>{body}</p>
                    
                    <div className="buttons">
                        <button onClick={() => set(false)} className="gray">Cancel</button>
                        <button onClick={click} className="red">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}