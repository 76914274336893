import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FiLogOut, FiMonitor, FiGlobe } from "react-icons/fi";
import { GrPowerCycle } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { FaGear } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { BsFillGridFill, BsPencilSquare } from "react-icons/bs";
import Brand from "../brand";

import './dashboard-nav.scss';

export default function DashboardNav() {

    const location = useLocation();
    const user = useSelector(state => state.user);
    const tickets = useSelector(state => state.tickets);

    const [supportNotification, setSupportNotification] = useState(false);

    useEffect(() => {
        if (tickets?.length) {
            let notSeen = false;
            for (let i = 0; i < tickets.length; i++) {
                let ticket = tickets[i];
                let messages = ticket.messages;

                for (let j = 0; j < messages.length; j++) {
                    if (messages[j].seen == false && messages[j].sender == "admin") {
                        setSupportNotification(true);
                        notSeen = true;
                        break;
                    }
                }

                if (notSeen) break;
            }
            if (!notSeen) setSupportNotification(false);
        }
    }, [tickets])

    if (!user) return <></>
    return (
        <div id="dashboard-nav">
            <div className="items">
                <div className="brand-container">
                    <Link to="/dashboard/overview">
                        <Brand />
                        <img src="/images/logo.png" className="logo" />
                    </Link>
                </div>
                <Link 
                    className={["/dashboard", "/dashboard/overview"].includes(location.pathname) ? "active" : undefined}
                    to="/dashboard/overview"
                >
                    <FiMonitor /><span>Overview</span>
                </Link>
                <Link 
                    className={location.pathname.startsWith("/dashboard/templates") ? "active" : undefined} 
                    to="/dashboard/templates"
                >
                    <BsPencilSquare /><span>Generate</span>
                </Link>
                <Link 
                    className={location.pathname.startsWith("/dashboard/articles") ? "active" : undefined} 
                    to="/dashboard/articles"
                >
                    <BsFillGridFill /><span>Articles</span>
                </Link>
                <Link 
                    className={location.pathname.startsWith("/dashboard/websites") ? "active" : undefined} 
                    to="/dashboard/websites"
                >
                    <FiGlobe /><span>Websites</span>
                </Link>
                <Link 
                    className={location.pathname.startsWith("/dashboard/bulk") ? "active" : undefined} 
                    to="/dashboard/bulk"
                >
                    <GrPowerCycle /><span>Bulk</span>
                </Link>
                {/*
                <Link 
                    className={location.pathname.startsWith("/dashboard/affiliate") ? "active" : undefined} 
                    to="/dashboard/affiliate"
                >
                    <BsPeopleFill /><span>Affiliate</span>
                </Link>
                */}
                <Link 
                    className={location.pathname.startsWith("/dashboard/credits") ? "active" : undefined} 
                    to="/dashboard/credits"
                >
                    <FaCoins /><span>Credits</span>
                </Link>
                <Link 
                    className={(location.pathname.startsWith("/dashboard/tickets") ? "active " : "") + (supportNotification ? "notification" : "")}
                    to="/dashboard/tickets"
                >
                    <BiSupport /><span>Support</span>
                </Link>

                <BottomLinks />
            </div>
            <div className="bottom">
                <BottomLinks />
            </div>
        </div>
    )
}

function BottomLinks() {

    function logout() {
        localStorage.removeItem("login_token");
        window.location.href = "/login";
    }

    return (
        <>
            <Link className="bottom-link" to="/dashboard/settings"><FaGear /><span>Settings</span></Link>
            <a className="bottom-link" href="/" onClick={logout}><FiLogOut /><span>Logout</span></a>
        </>
    )
}