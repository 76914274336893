import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import templateReducer from "./reducers/templateReducer";
import ticketsReducer from "./reducers/ticketsReducer";
import websitesReducer from "./reducers/websitesReducer";
import articlesReducer from "./reducers/articlesReducer";
import lengthsReducer from "./reducers/lengthsReducer";
import statsReducer from "./reducers/statsReducer";

export default configureStore({
    reducer: {
        user: userReducer,
        tickets: ticketsReducer,
        template: templateReducer,
        websites: websitesReducer,
        articles: articlesReducer,
        lengths: lengthsReducer,
        stats: statsReducer,
    },
});