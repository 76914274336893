import './not-found.scss';

export default function NotFound() {
    return (
        <div id="not-found">
            <img src="/images/vectors/404.svg" />
            <h1>404</h1>
            <p>The page you're looking for doesn't exist</p>
        </div>
    )
}