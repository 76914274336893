import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    BsInfoCircle,
    BsChatLeftTextFill,
    BsCreditCard2Front,
    BsFillClockFill,
    BsChevronDown
} from 'react-icons/bs';
import { BiLogoBitcoin } from 'react-icons/bi';
import Selection from '../../../components/selection';
import Brand from '../../../components/brand';
import Cookie from '../../../helpers/cookie';
import './credits.scss';

const cookie = new Cookie();

export default function Credits() {

    const [method, setMethod] = useState("Cryptocurrency");
    const [rateFetched, setRateFetched] = useState(false);
    const [totalLoading, setTotalLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [credits, setCredits] = useState(1000);
    const [rate, setRate] = useState(0);
    const [total, setTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [methodSelection, setMethodSelection] = useState(false);
    const [currencySelection, setCurrencySelection] = useState(false);
    const [currency, setCurrency] = useState(localStorage.getItem("currency") || "USD");
    const [pendingTransactions, setPendingTransactions] = useState(0);

    useEffect(() => {
        // Get 1000 credits price
        fetch(process.env.REACT_APP_API + "/credits/credit-price", {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        })
        .then(data => data.json())
        .then(data => {
            let price = data?.price || 0.05;
            let _total = (credits / 1000 * price);

            setRate(price);
            setTotal(credits < 50_000 ? _total : (_total - _total / 10));
            setDiscount(credits < 50_000 ? 0 : _total);
            setRateFetched(!rateFetched);
            setTotalLoading(false);
        });

        // Get pending transactions
        fetch(process.env.REACT_APP_API + "/credits/transactions", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data?.transactions?.length) {
                toast.dismiss();
                setPendingTransactions(data.transactions.length);
                toast.info("You have " + data.transactions.length + " pending transactions.");
            }
        })
    }, []);

    useEffect(() => {
        // Fetch USDTRY rate
        if (currency == "TRY" && rate && !cookie.getCookie("try")) {
            setTotalLoading(true);
            fetch(process.env.REACT_APP_API + "/credits/try-rate", {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            })
            .then(data => data.json())
            .then(data => {
                if (data?.rate) {
                    let currencyRate = data.rate;
                    cookie.setCookie("try", currencyRate, 1);
                    setRate(rate * currencyRate);
                    setMethod("Credit Card");
                    setTotalLoading(false);
                } else {
                    setCurrency("USD");
                    setMethod("Cryptocurrency");
                }
            })
            .catch(err => {
                console.error(err);
                setCurrency("USD");
                setTotalLoading(false);
            });
        }

        // If TRY rate exists in cookie
        else if (cookie.getCookie("try") && currency == "TRY") {
            setRate(rate * Number(cookie.getCookie("try") || 1));
            setMethod("Credit Card");
        }

        else if (currency == "USD") {
            setRate(rate / Number(cookie.getCookie("try") || 1));
            setMethod("Cryptocurrency");
        }
    }, [rateFetched]);

    useEffect(() => {
        let _total = (credits / 1000 * rate);
        setTotal(credits < 50_000 ? _total : (_total - _total / 10 ));
        setDiscount(credits < 50_000 ? 0 : _total);
    }, [credits, rate]);

    // Payment Function
    function proceed() {
        let endpoint;

        if (method == "Credit Card") endpoint = "paytr-link";
        else if (method == "Cryptocurrency") endpoint = "coinbase-charge";

        if (!endpoint) return toast.error("Unknown payment method");

        setButtonLoading(true);

        fetch(process.env.REACT_APP_API + "/credits/" + endpoint, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                amount: credits,
                currency
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.link) window.location.href = data.link;
            else if (data?.message) {
                toast.error(data.message);
                setButtonLoading(false);
            }
        });
    }

    return (
        <div id="credits">
            <div className="container">
                <div className="form">
                    <div className="inputs">
                        <div className="selections">
                            <div>
                                <div className="selection-container">
                                    <span>Payment Method</span>
                                    <button onClick={() => setMethodSelection(true)}>
                                        { method == "Credit Card" && <BsCreditCard2Front /> }
                                        { method == "Cryptocurrency" && <BiLogoBitcoin /> }
                                        { method || "Select a Payment Method" }
                                        <BsChevronDown className="down-arrow" />
                                    </button>
                                    <div>
                                        <Selection
                                            items={["Credit Card", "Cryptocurrency"]}
                                            click={e => setMethod(e.target.innerText)}
                                            visible={methodSelection}
                                            setVisible={setMethodSelection}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="currency">
                                <div className="selection-container">
                                    <span>Currency</span>
                                    <button onClick={() => setCurrencySelection(true)}>
                                        { currency == "USD" && <span>$</span> }
                                        { currency == "TRY" && <span>₺</span> }
                                        { currency || "Select a Currency" }
                                        <BsChevronDown className="down-arrow" />
                                    </button>
                                    <div>
                                        <Selection
                                            items={["USD", "TRY"]}
                                            click={e => {
                                                setRateFetched(!rateFetched);
                                                setCurrency(e.target.innerText);
                                            }}
                                            visible={currencySelection}
                                            setVisible={setCurrencySelection}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label><Brand /> Credits Amount</label>
                            <input type="number" onChange={e => setCredits(e.target.value)} value={credits} placeholder="1000" />
                        </div>
                        <div className="credits-fast">
                            <button onClick={() => setCredits(50_000)}>50,000</button>
                            <button onClick={() => setCredits(100_000)}>100,000</button>
                            <button onClick={() => setCredits(250_000)}>250,000</button>
                            <button onClick={() => setCredits(500_000)}>500,000</button>
                            <button onClick={() => setCredits(1_000_000)}>1,000,000</button>
                        </div>
                        <small><BsInfoCircle size={14} />Credits are equivalent to words that you get as output when you generate a content.</small>
                    </div>
                    <div className="total">
                        <div className="text">
                            <h4>Total: </h4>
                            { !totalLoading 
                                ? credits < 50_000
                                    ? <span>{(total || 0)?.toFixed(2)}{currency == "TRY" ? "₺" : "$"}</span>
                                    : (
                                        <div className="price-with-discount">
                                            <span>{(total || 0)?.toFixed(2)}{currency == "TRY" ? "₺" : "$"}</span>
                                            <span className='discount'>{(discount || 0)?.toFixed(2)}{currency == "TRY" ? "₺" : "$"}</span>
                                        </div>
                                    )
                                : <img src="/images/loader.svg" className="loader" />
                            }
                        </div>
                        <a
                            className={buttonLoading ? "button-loading" : ""}
                            onClick={() => {
                                if (!buttonLoading) proceed()
                            }}
                        >
                            { buttonLoading ? <img src="/images/loader.svg" /> : "Proceed Payment" }
                        </a>
                    </div>
                </div>
                <div className="description">
                    <div className="payment-info">
                        <h5><BsChatLeftTextFill /> Information</h5>
                        { method == "Credit Card" &&
                        <>
                            <p>We use <b>PayTR Virtual POS and Payment Solutions</b> as credit card payment services. All of the acceptance & refund operations will be done by PayTR.</p>
                            <p>In case of any problems, you can always reach out to our <Link to="/dashboard/tickets">support</Link>, if you want to reach to <b>PayTR</b> support team you can contact with from the following URL: <a href="https://www.paytr.com/iletisim" target="_blank">https://www.paytr.com/iletisim</a></p>
                            <p>If you have questions in your mind, you can always <Link to="/dashboard/tickets">contact</Link> us</p>
                        </>
                        }
                        { method == "Cryptocurrency" &&
                        <>
                            <p>We use <b>Coinbase Commerce</b> as cryptocurrency payment services. All of the payment acceptance operations will be done by Coinbase.</p>
                            <p>In case of any problems, you can always reach out to our <Link to="/dashboard/tickets">support</Link>, if you want to reach to <b>Coinbase</b> support team you can contact with from the following URL: <a href="https://help.coinbase.com/en/contact-us" target="_blank">https://help.coinbase.com/en/contact-us</a></p>
                            <p>If you have questions in your mind, you can always <Link to="/dashboard/tickets">contact</Link> us</p>
                        </>
                        }
                    </div>

                    { pendingTransactions > 0 &&
                        <div className="pending-transactions">
                            <h5><BsFillClockFill />Pending Transactions</h5>
                            <p>You have <b>{pendingTransactions}</b> pending transactions, pending transactions will be automatically removed after 1 day if payment is not done.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}