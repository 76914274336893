import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Selection from '../selection';

import { HiOutlineStatusOnline } from 'react-icons/hi';
import { HiOutlineGlobeAlt } from 'react-icons/hi2';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { BiCategoryAlt } from "react-icons/bi";
import { FiX } from 'react-icons/fi';

import './publish-modal.scss';

export default function PublishModal({
    set,
    publishFunc,
    length,
}) {
    const websites = useSelector(state => state.websites);

    const [urls, setUrls] = useState([]);
    const [status, setStatus] = useState(localStorage.getItem("publish-status") || "publish");
    const [website, setWebsite] = useState(localStorage.getItem("website") || "");
    const [category, setCategory] = useState(localStorage.getItem("publish-category") || "");
    const [categories, setCategories] = useState([]);

    // Selections
    const [statusSelection, setStatusSelection] = useState(false);
    const [websiteSelection, setWebsiteSelection] = useState(false);
    const [categorySelection, setCategorySelection] = useState(false);

    useEffect(() => {
        let data_urls = [];
        websites.forEach(website => data_urls.push(website.url));
        setUrls(data_urls);
    }, []);

    // Set website categories
    useEffect(() => {
        if (website) {
            setCategories([]);
            fetch(process.env.REACT_APP_API + "/website/categories?url=" + website, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("login_token")
                }
            })
            .then(data => data.json())
            .then(data => {
                if (data.success) {
                    let raw_categories = data?.categories;
                    setCategories(raw_categories || []);

                    // Category management
                    if (!raw_categories.map(c => c.name).includes(category)) {
                        setCategory("");
                        if (category === localStorage.getItem("publish-category"))
                            localStorage.removeItem("publish-category");
                    }
                }
                else console.log(data?.message);
            });
        }
    }, [website]);

    function close(e) {
        if (!e.target.closest("#publish-modal .content")) set(false);
    }

    return (
        <div id="publish-modal" onClick={close}>
            <div className="content">
                <div className="top">
                    <h4>Publish Article</h4>
                    <FiX onClick={() => set(false)} />
                </div>
                <div className="body">
                    <p>You're going to publish {length} article{length != 1 ? "s" : ""}</p>
                    
                    <div className="selection-container">
                        <span>Choose a website</span>
                        <button onClick={
                            () => {
                                setWebsiteSelection(true);
                                setStatusSelection(false);
                                setCategorySelection(false);
                            }}
                        >
                            <HiOutlineGlobeAlt />
                            {website || "Select a Website"}
                            <BsChevronDown className="down-arrow" />
                        </button>
                        <div>
                            <Selection
                                items={urls}
                                click={e => {
                                    setWebsite(e.target.innerText);
                                    localStorage.setItem("website", e.target.innerText);
                                }}
                                visible={websiteSelection}
                                setVisible={setWebsiteSelection}
                                customCSS={{"main": { width: "350px" }}}
                            />
                        </div>
                    </div>

                    <div className="selection-container">
                        <span>Choose post status</span>
                        <button onClick={
                            () => {
                                setStatusSelection(true);
                                setWebsiteSelection(false);
                                setCategorySelection(false);
                            }}
                        >
                            <HiOutlineStatusOnline />
                            {status || "Select Post Status"}
                            <BsChevronDown className="down-arrow" />
                        </button>
                        <div>
                            <Selection
                                items={["publish", "future", "draft", "pending", "private"]}
                                click={e => {
                                    setStatus(e.target.innerText);
                                    localStorage.setItem("publish-status", e.target.innerText);
                                }}
                                visible={statusSelection}
                                setVisible={setStatusSelection}
                            />
                        </div>
                    </div>

                    <div className="selection-container">
                        <span>Choose a category <small style={{ color: "gray" }}>(Optional)</small></span>
                        <div className="selection-with-close">
                            <button onClick={
                                () => {
                                    setCategorySelection(true);
                                    setWebsiteSelection(false);
                                    setStatusSelection(false);
                                }}
                            >
                                <BiCategoryAlt />
                                {category || "Select a Category"}
                                <BsChevronDown className="down-arrow" />
                            </button>

                            { category && (
                                <a
                                    className="remove"
                                    onClick={() => setCategory("")}
                                >
                                    <AiFillCloseCircle />
                                </a>
                            )}
                        </div>
                        <div>
                            <Selection
                                items={categories.map(c => c.name)}
                                click={e => {
                                    setCategory(e.target.innerText);
                                    localStorage.setItem("publish-category", e.target.innerText);
                                }}
                                customCSS={{ "body": { maxHeight: "200px" } }}
                                visible={categorySelection}
                                setVisible={setCategorySelection}
                            />
                        </div>
                    </div>

                    <div className="buttons">
                        <button
                            onClick={() => set(false)}
                            className="gray"
                        >
                            Cancel
                        </button>
                        <button 
                            onClick={() => {
                                set(false);
                                let base = { status, url: website };
                                if (category) base["category"] = categories?.filter(c => c?.name == category)?.[0]?.id;
                                return publishFunc(base);
                            }}
                            disabled={!status || !website}
                        >
                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}