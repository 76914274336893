import { FaCoins } from 'react-icons/fa6';
import './credits.scss';

export default function Credits({ amount }) {
    return (
        <div className="credits">
            <FaCoins />
            <span className="credits-text">{amount || 0}</span>
        </div>
    )
}