import { createSlice } from "@reduxjs/toolkit";

export const ticketsSlice = createSlice({
    name: "tickets",
    initialState: [],
    reducers: {
        setTickets: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setTickets } = ticketsSlice.actions;
export default ticketsSlice.reducer;