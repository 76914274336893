import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: localStorage.getItem("login_token") ? true : null,
    reducers: {
        setUser: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;