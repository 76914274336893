import { createSlice } from "@reduxjs/toolkit";

export const statsSlice = createSlice({
    name: "stats",
    initialState: {
        totalArticles : 0,
        publishedArticles: 0,
        generatedImages: 0,
        consumedCredits: 0,
        status: "pending"
    },
    reducers: {
        setStats: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setStats } = statsSlice.actions;
export default statsSlice.reducer;