import { useEffect } from 'react';
import Brand from '../../../components/brand';
import './faq.scss';

export default function FAQ() {

    useEffect(() => {
        document.title = "FAQ - DURBUN";
    }, []);

    return (
        <div id="faq">
            <div className="content">
                <h2>Frequently Asked Questions</h2>

                <div className="questions">
                    <div className="container">
                        <div className="question">
                            <h4>What will I have to pay for?</h4>
                        </div>
                        <div className="answer">
                            <p>You only pay for the articles. Creation of articles required <Brand /> credits. Each credit represents an output word. Your first 5000 credits are free.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <h4>Can I publish my articles in my WordPress blog?</h4>
                        </div>
                        <div className="answer">
                            <p>You can publish your articles directly through our dashboard. You don't have to copy & paste articles by yourself. Our WordPress integration publishes the articles with meta descriptions, primary keywords, featured images (if you want), and all the other necessary parts!</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <h4>How can I buy credits?</h4>
                        </div>
                        <div className="answer">
                            <p>You can buy credits from the Credits panel that is located in Sidenav in the dashboard. The payment methods are currently credit card and cryptocurrency.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <h4>Can I use my credits anytime?</h4>
                        </div>
                        <div className="answer">
                            <p>Yes! You can use your credits anytime you want. You don't have to use them right after you buy them.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <h4>How can I contact you?</h4>
                        </div>
                        <div className="answer">
                            <p>You can contact us by sending an email to contact@durbun.net, or you can create a ticket in the dashboard.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <h4>What should I do if any problem occurs?</h4>
                        </div>
                        <div className="answer">
                            <p>Contact us and we'll be sure your problem is resolved. Also, we can definitely refund your credits in case of problems that waste your credits.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}