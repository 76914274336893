import { useEffect } from 'react';
import '../../assets/scss/text_page.scss';

export default function ToS() {

    useEffect(() => { document.title = "Terms of Usage - DURBUN"; }, []);
    
    return (
        <div id="text-page">
            <h1>Terms of Usage</h1>
            <p><b>Effective Date: 28/08/2023</b></p>

            <p>Welcome to DURBUN.net. By accessing or using our AI article creating service, you agree to comply with the following Terms of Service ("Terms"). Please read these terms carefully before using our service.</p>

            <h2>Acceptance of Terms:</h2>
            <h3>1.1 Agreement:</h3>
            <p>These Terms constitute a legally binding agreement between you and DURBUN.net. By accessing or using our service, you acknowledge that you have read, understood, and agree to be bound by these Terms.</p>

            <h3>1.2 Age Requirement:</h3>
            <p>You must be at least 18 years old to use our service. If you are under 18, you may use our service only with the involvement and consent of a parent or legal guardian.</p>

            <h2>Use of Service:</h2>
            <h3>2.1 Prohibited Activities:</h3>
            <p>You agree <b>not</b> to:</p>

            <ul>
                <li>Distribute, copy, reproduce, modify, or create derivative works of our service or any part thereof.</li>
                <li>Reverse engineer, decompile, or disassemble any aspect of our service.</li>
                <li>Use our service for any illegal, harmful, or fraudulent activities.</li>
                <li>Attempt to gain unauthorized access to our systems, networks, or user accounts.</li>
                <li>Use our service in any way that could disrupt, damage, or interfere with its operation.</li>
            </ul>

            <h2>User Responsibilities:</h2>
            <h3>3.1 Content Creation:</h3>
            <p>You are solely responsible for the content created using our service. You agree to create content that adheres to applicable laws and regulations and does not infringe on the rights of any third parties.</p>

            <h3>3.2 Informal Purposes:</h3>
            <p>The content generated using our service is intended for informational purposes only and should not be considered professional, expert, or authoritative advice.</p>

            <h2>Intellectual Property:</h2>
            <h3>4.1 Ownership:</h3>
            <p>We retain all rights, title, and interest in and to our service, including any intellectual property rights. You do not acquire any rights to our service except as expressly stated in these Terms.</p>

            <h3>4.2 Content Ownership:</h3>
            <p>You retain ownership of the content you create using our service. However, by using our service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and distribute the content for the purpose of operating and promoting our service.</p>

            <h2>Termination:</h2>
            <h3>5.1 Termination:</h3>
            <p>We reserve the right to suspend or terminate your access to our service at any time for any reason, including if you violate these Terms.</p>

            <h3>5.2 Effect of Termination:</h3>
            <p>Upon termination, your license to use our service will immediately cease, and you must cease all use of our service and any content created using it.</p>

            <h2>Changes to Terms:</h2>
            <h3>6.1 Updates:</h3>
            <p>We may update these Terms from time to time. Any changes will be effective upon posting the updated terms on our website. It is your responsibility to review these Terms periodically.</p>

            <h2>Contact Us:</h2>
            <h3>7.1 Questions:</h3>
            <p>If you have any questions, concerns, or inquiries about these Terms, please contact us at contact@durbun.net.</p>

            <p>By using DURBUN.net, you agree to abide by these Terms of Service. If you do not agree with these Terms, you may not use our service.</p>
        </div>
    )
}