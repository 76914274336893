import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import socket from "../../socket";

// Redux States
import { setTickets } from "../../store/reducers/ticketsReducer";
import { setArticles } from "../../store/reducers/articlesReducer";
import { setWebsites } from "../../store/reducers/websitesReducer";

// Components
import DashboardNav from "../../components/dashboard-nav";
import DashboardHeader from "../../components/dashboard-header";

// Pages
import Bulk from "./bulk";
import Tickets from "./tickets";
import Credits from "./credits";
import Settings from "./settings";
import Articles from "./articles";
import Overview from "./overview";
import Websites from "./websites";
import Affiliate from './affiliate';
import Templates from "./templates";

// Modals
import PublishStatusModal from "../../components/publish-status-modal";

import './dashboard.scss';

export default function Dashboard() {

    const dispatch = useDispatch();

    // Post publishing
    const [publishResult, setPublishResult] = useState(null);
    const [publishProgress, setPublishProgress] = useState(null);

    // Modal states
    const [publishStatusModal, setPublishStatusModal] = useState(false);

    useEffect(() => {
        document.title = "Dashboard - DURBUN";

        // Get tickets
        fetch(process.env.REACT_APP_API + "/ticket/get-tickets", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data?.tickets) dispatch(setTickets(data.tickets));
        });

        // Get articles
        fetch(process.env.REACT_APP_API + "/article/get-articles", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                page: 1,
                perPage: 20
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.articles) {
                dispatch(setArticles({
                    data: data.articles,
                    status: "fetched",
                    max: data.max,
                }));
            }
        });

        // Get websites
        fetch(process.env.REACT_APP_API + "/website/get-websites", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            }
        })
        .then(data => data.json())
        .then(data => {
            if (data?.websites) dispatch(setWebsites(data.websites));
        });
    }, []);

    useEffect(() => {
        // Off the existing connections
        socket.off("listen-publishing");

        /* Socket Connections */
        // WordPress Publish Listener
        socket.on("publishing-listener", async response => {
            if (response?.status === "started") {
                setPublishStatusModal(true);
                setPublishResult(null);
            }

            else if (response?.status === "pending") {
                setPublishProgress(response.progress);
            }

            else if (response?.success) {
                setPublishStatusModal(true);
                setPublishResult(response);

                // Get articles
                fetch(process.env.REACT_APP_API + "/article/get-articles", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'access-token': localStorage.getItem("login_token")
                    },
                    body: JSON.stringify({
                        page: 1,
                        perPage: 20
                    })
                })
                .then(data => data.json())
                .then(data => {
                    if (data?.articles) {
                        dispatch(setArticles({
                            data: data.articles,
                            status: "fetched",
                            max: data.max,
                        }));
                    }
                });
            }
            else if (!response?.success && response?.message) {
                setPublishStatusModal(true);
                setPublishResult(response);
            }
            else if (!response?.success) {
                setPublishStatusModal(true);
                setPublishResult({ message: "An error occured, please contact with support team." });
            }
        });
    }, [publishResult]);

    return (
        <>
        <DashboardNav />
        <div id="dashboard">
            <DashboardHeader />

            <div className="content">
                <Routes>
                    <Route path="/overview" element={<Overview />} />
                    <Route path="/settings/*" element={<Settings />} />
                    <Route path="/templates/*" element={<Templates />} />
                    <Route path="/articles/*" element={<Articles />} />
                    <Route path="/credits/" element={<Credits />} />
                    <Route path="/websites/" element={<Websites />} />
                    <Route path="/bulk/*" element={<Bulk />} />
                    <Route path="/affiliate/*" element={<Affiliate />} />
                    <Route path="/tickets/*" element={<Tickets />} />
                    <Route path="*" element={<Overview />} />
                </Routes>
            </div>

            {/* Modals */}
            { publishStatusModal && (
                <PublishStatusModal
                    set={setPublishStatusModal}
                    publishResult={publishResult}
                    publishProgress={publishProgress}
                />
            )}
        </div>
        </>
    )
}