import socket from '../socket';

export async function publish({
    url,
    status,
    selected = { $match: { id: null } },
    filter = {},
    category = undefined
}) {
    return new Promise((resolve, reject) => {
        if (!selected) return reject("Please select some articles first.");
        else if (!status || !url) return reject("Please fill all of the fields.");

        let params = {
            url,
            status,
        };

        if (category === undefined) params["category"] = category;

        if (selected.$match?.$or?.length === 1)
            selected.$match = selected.$match.$or[0];

        // Multiple publishing
        socket.emit("publish-post", {
            filter,
            selected: selected.$match,
            access_token: localStorage.getItem("login_token"),
            ...params
        });

        return resolve({ multiple: true });
    });
}