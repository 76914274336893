
import { FiX } from 'react-icons/fi';
import { MdFileDownload } from "react-icons/md";

import './download-modal.scss';
import { useState } from 'react';

export default function DownloadModal({
    set,
    click,
    ext: ext_og
}) {

    const [ext, setExt] = useState("zip"); // zip | file
    console.log(ext);

    function close(e) {
        if (!e.target.closest("#download-modal .content")) set(false);
    }

    return (
        <div id="download-modal" onClick={close}>
            <div className="content">
                <div className="top">
                    <h4>Download</h4>
                    <FiX onClick={() => set(false)} />
                </div>
                <div className="body">
                    { !ext_og && (
                        <>
                            <p>Install as...</p>
                            <div className="btn-selection">
                                <button
                                    className={ext === "zip" ? "active" : undefined}
                                    onClick={() => { setExt("zip") }}
                                >Zip</button>
                                <button
                                    className={ext === "file" ? "active" : undefined}
                                    onClick={() => { setExt("file") }}
                                >Files</button>
                            </div>
                        </>
                    )}

                    <p>Download the article as .txt or .html</p>
                    <div className="buttons">
                        <button onClick={() => click("html", ext_og || ext)}>
                            <MdFileDownload />Download as <b>HTML File</b> (.html)
                        </button>
                        <button onClick={() => click("txt", ext_og || ext)}>
                            <MdFileDownload />Download as <b>Text File</b> (.txt)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}