import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { LuKey } from "react-icons/lu";
import { GrMultiple } from "react-icons/gr";
import { HiLanguage } from 'react-icons/hi2';
import { PiPencilLineFill } from "react-icons/pi";
import { BsPencilSquare, BsFire, BsMagic, BsPencilFill } from 'react-icons/bs';
import { RiBook2Fill, RiAdvertisementLine } from "react-icons/ri";
import { FaWandMagicSparkles, FaCodeCompare } from "react-icons/fa6";

import { HiSortDescending, HiSortAscending } from "react-icons/hi";
import { LiaPencilRulerSolid, LiaChartBarSolid, LiaPenNibSolid, LiaFileAltSolid } from "react-icons/lia";

import TemplateLink from '../../../components/template';
import Template from './template';
import NotFound from '../../404';

import './templates.scss';

export default function Templates() {

    // Navigation management -->
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.user);
    const templateTitles = useSelector(state => state.template);
    const isPrivileged = user?.role === "admin" || user?.role === "tier2";

    const [method, setMethod] = useState(null);

    const templateElements = {
        "articles": {
            "article"          : <TemplateLink click={() => handleTemplate("article")} icon={<BsPencilSquare />} title={<>{templateTitles["article"]}<BsFire size={20} /></>} desc="Generate a whole single article for your blog" />,
            "bulk"             : <TemplateLink click={() => navigate("/dashboard/bulk")} icon={<GrMultiple />} title={<>{templateTitles["bulk"]}<BsFire size={20} /></>} desc="Generate multiple articles with a single click" />,
            "enhanced-article" : isPrivileged ? <TemplateLink click={() => handleTemplate("enhanced-article")} icon={<PiPencilLineFill />} title={templateTitles["enhanced-article"]} desc="Enhanced Article" /> : <></>,
            "article-2"        : isPrivileged ? <TemplateLink click={() => handleTemplate("article-2")} icon={<BsPencilFill />} title={<>{templateTitles["article-2"]}<BsFire size={20} /></>} desc="More advanced articles" /> : <></>,
            "review"           : isPrivileged ? <TemplateLink click={() => handleTemplate("review")} icon={<FaWandMagicSparkles />} title={templateTitles["review"]} desc="An article type optimized for product reviews" /> : <></>,
            "spinner"          : <TemplateLink click={() => handleTemplate("spinner")} icon={<LiaPencilRulerSolid />} title={templateTitles["spinner"]} desc="Enter your content and get a spinned version" />,
            "intro"            : <TemplateLink click={() => handleTemplate("intro")} icon={<HiSortAscending />} title={templateTitles["intro"]} desc="Generate introduction paragraphs for your article" />,
            "conclusion"       : <TemplateLink click={() => handleTemplate("conclusion")} icon={<HiSortDescending />} title={templateTitles["conclusion"]} desc="Generate conclusion paragraphs for your article" />,
            "faq"              : <TemplateLink click={() => handleTemplate("faq")} icon={<LiaChartBarSolid />} title={templateTitles["faq"]} desc={"Generate \"Frequently Asked Questions\" section for your article/blog"} />,
        },
        "seo": {
            "keyword"          : <TemplateLink click={() => handleTemplate("keyword")} icon={<LuKey />} title={<>{templateTitles["keyword"]}<BsFire size={20} /></>} desc="Generate keyword ideas for your articles" />,
            "meta"             : <TemplateLink click={() => handleTemplate("meta")} icon={<LiaFileAltSolid />} title={templateTitles["meta"]} desc="Generate meta description for your blog/article" />,
            "marketing"        : <TemplateLink click={() => handleTemplate("marketing")} icon={<RiAdvertisementLine />} title={templateTitles["marketing"]} desc="Generate a marketing text for your brand" />,
        },
        "titles": {
            "title"            : <TemplateLink click={() => handleTemplate("title")} icon={<LiaPenNibSolid />} title={templateTitles["title"]} desc="Generate SEO-friendly corresponding titles for your keywords" />,
            "title-spinner"    : <TemplateLink click={() => handleTemplate("title-spinner")} icon={<BsMagic />} title={templateTitles["title-spinner"]} desc="Enter a title, get a better one in terms of SEO" />,
        },
        "other": {
            "translate"        : <TemplateLink click={() => handleTemplate("translate")} icon={<HiLanguage />} title={templateTitles["translate"]} desc="Enter your content and translate it to any language you want" />,
            "comparison"       : <TemplateLink click={() => handleTemplate("comparison")} icon={<FaCodeCompare />} title={templateTitles["comparison"]} desc="Compare your products" />,
            "book"             : <TemplateLink click={() => handleTemplate("book")} icon={<RiBook2Fill />} title={templateTitles["book"]} desc="Generate summary and review of a book" />,
            "story"            : <TemplateLink click={() => handleTemplate("story")} icon={<FaWandMagicSparkles />} title={templateTitles["story"]} desc="Generate an engaging and unique story" />,
        }
    }

    useEffect(() => {
        let path = location.pathname;
        let slashCount = (path.match(/\//g) || []).length;

        if (slashCount >= 3) handleTemplate(path.split("/").at(-1));
        else setMethod(null);
    }, [location.pathname]);

    function handleTemplate(_) {
        setMethod(_);
        navigate("/dashboard/templates/"+_);
    }

    return (
        <div id="templates" style={!method ? { display: "block" } : { boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.1)", borderRadius: "10px" }}>
            {!method ? (
            <>
                { Object.keys(templateElements).map((key, i) => {
                    if (!user) return <></>;
                    return (
                    <>
                        { (Object.keys(templateElements[key]).filter(t => !user?.disabledTemplates?.includes(t)).length > 0) && (
                            <h2 key={"t-"+i}>
                                {
                                    key === "articles"
                                        ? "Articles"
                                        : key === "seo"
                                            ? "SEO Tools"
                                            : key === "titles"
                                                ? "Titles"
                                                : key === "other"
                                                    ? "Other Tools"
                                                    : ""
                                }
                            </h2>
                        )}
                        <section key={i} className={key}>
                            {Object.keys(templateElements[key]).map((template, i) => {
                                if (!user?.disabledTemplates?.includes(template))
                                    return templateElements[key][template]
                                return <></>
                            })}
                        </section>
                    </>)
                })}
            </> ) : (
            <div className="template-content">
                <Routes>
                    <Route path="/article" element={
                        <Template
                            type="article"
                            title={templateTitles["article"]}
                            inputs={["keywords", "title", "subtitles", "language", "website", "image", "length", "model", "video", "faq"]}
                        />
                    } />
                    { isPrivileged && 
                        <Route path="/enhanced-article" element={
                            <Template
                                type="enhanced-article"
                                title={templateTitles["enhanced-article"]}
                                inputs={["keywords", "language", "website"]}
                            />
                        } />
                    }
                    { isPrivileged && 
                        <Route path="/review" element={
                            <Template
                                type="review"
                                title={templateTitles["review"]}
                                inputs={["keywords", "language", "website", "image"]}
                            />
                        } />
                    }
                    { isPrivileged && 
                        <Route path="/article-2" element={
                            <Template
                                type="article-2"
                                title={templateTitles["article-2"]}
                                inputs={["title", "language"]}
                            />
                        } />
                    }
                    <Route path="/keyword" element={
                        <Template
                            type="keyword"
                            title={templateTitles["keyword"]}
                            inputs={["keywords", "language", "model", "length-2"]}
                            descriptions={{
                                "length-2": "Enter how many keywords you want to generate"
                            }}
                        />
                    } />
                    <Route path="/intro" element={
                        <Template
                            type="intro"
                            title={templateTitles["intro"]}
                            inputs={["keywords", "title", "language", "model"]}
                        />
                    } />
                    <Route path="/conclusion" element={
                        <Template
                            type="conclusion"
                            title={templateTitles["conclusion"]}
                            inputs={["keywords", "title", "language", "model"]}
                        />
                    } />
                    <Route path="/faq" element={
                        <Template
                            type="faq"
                            title={templateTitles["faq"]}
                            inputs={["keywords", "subtitles", "language", "length-2", "model"]}
                            descriptions={{
                                "length-2": "Enter how many FAQ questions you want to generate"
                            }}
                        />
                    } />
                    <Route path="/spinner" element={
                        <Template
                            type="spinner"
                            title={templateTitles["spinner"]}
                            inputs={["content", "language", "model"]}
                        />
                    } />
                    <Route path="/translate" element={
                        <Template
                            type="translate"
                            title={templateTitles["translate"]}
                            inputs={["content", "language", "model"]}
                        />
                    } />
                    <Route path="/title" element={
                        <Template
                            type="title"
                            title={templateTitles["title"]}
                            inputs={["keywords", "language", "length-2", "model"]}
                            descriptions={{
                                "length-2": "Enter how many titles you want to generate"
                            }}
                        />
                    } />
                    <Route path="/title-spinner" element={
                        <Template
                            type="title-spinner"
                            title={templateTitles["title-spinner"]}
                            inputs={["title", "multiple-title", "language", "model"]}
                        />
                    } />
                    <Route path="/meta" element={
                        <Template
                            type="meta"
                            title={templateTitles["meta"]}
                            inputs={["keywords", "language", "model"]}
                        />
                    } />
                    <Route path="/marketing" element={
                        <Template
                            type="marketing"
                            title={templateTitles["marketing"]}
                            inputs={["explain", "language", "model"]}
                            descriptions={{
                                "explain": {
                                    "desc": "Explain your brand and what is it about including its name. This template will create you a marketing text.",
                                    "example": "durbun.net is an AI article generator with features like WordPress publishing, image generation etc."
                                }
                            }}
                        />
                    } />
                    <Route path="/book" element={
                        <Template
                            type="book"
                            title={templateTitles["book"]}
                            inputs={["keywords", "language", "model", "image"]}
                            descriptions={{
                                "keywords": {
                                    "desc": "Enter the book name",
                                    "example": "A Brief History of Time",
                                    "single": true
                                }
                            }}
                        />
                    } />
                    <Route path="/story" element={
                        <Template
                            type="story"
                            title={templateTitles["story"]}
                            inputs={["title", "multiple-title", "language", "model", "image", "website"]}
                            descriptions={{
                                "title": {
                                    "desc": "Enter the title of your story.",
                                    "example": "Goldilocks and the three bears"
                                }
                            }}
                        />
                    } />
                    <Route path="/comparison" element={
                        <Template
                            type="comparison"
                            title={templateTitles["comparison"]}
                            inputs={["title", "language", "model", "website"]}
                            descriptions={{
                                "title": {
                                    "desc": "Enter 2 items to compare.",
                                    "example": "iPhone 11 vs Samsung Galaxy S20"
                                }
                            }}
                        />
                    } />

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            )}
        </div>
    )
}