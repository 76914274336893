import { useEffect } from 'react';
import '../../assets/scss/text_page.scss';

export default function Privacy() {

    useEffect(() => { document.title = "Privacy Policy - DURBUN"; }, []);

    return (
        <div id="text-page">
            <h1>Privacy Policy</h1>
            <p><b>Effective Date: 28/08/2023</b></p>

            <p>DURBUN.net is an article-creating service that uses AI technology. At DURBUN.net, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines the types of data we collect, how we use and store it, and the measures we take to safeguard your information. By using our AI article creating service, you consent to the practices described in this policy.</p>

            <h2>Information We Collect:</h2>
            <h3>1.1 Personal Information:</h3>
            <p>We collect the following personal information:</p>

            <p>
                <b>Name:</b> To personalize your experience and interactions with our service.<br />
                <b>Email Address:</b> To communicate with you, provide customer support, send confirmation email, and send important updates.<br />
                <b>IP Address:</b> To enhance the security of our platform and prevent fraudulent activities.<br />
                <b>Last Login Date:</b> To monitor and improve our services and track account activity.
            </p>
            
            <h3>1.2 Encrypted Passwords:</h3>
            <p>We securely store passwords using advanced encryption methods to protect your account and personal information.</p>

            <h2>Use of Information:</h2>
            <h3>2.1 Personalization and Communication:</h3>
            <p>We use your name and email address to personalize your interactions with our service, provide customer support, and communicate important information related to your account and our services.</p>

            <h3>2.2 Security and Fraud Prevention:</h3>
            <p>Your IP address and last login date are used for security purposes, such as identifying and preventing unauthorized access and fraudulent activities.</p>

            <h2>Data Storage:</h2>
            <h3>3.1 Encryption:</h3>
            <p>We prioritize the security of your data and ensure that passwords are stored using strong encryption methods to prevent unauthorized access.</p>

            <h3>3.2 Data Retention:</h3>
            <p>We retain your personal information as long as it is necessary to provide the services you have requested and to fulfill legal obligations. You have the right to request the deletion of your account and personal information at any time.</p>

            <h2>Sharing of Information:</h2>
            <h3>4.1 Third Parties:</h3>
            <p>We do not share your personal information with third parties except as required by law or to protect our rights and interests.</p>

            <h2>Legal Compliance:</h2>
            <h3>5.1 Legal Obligations:</h3>
            <p>We may disclose your personal information in response to valid legal processes, court orders, or governmental requests.</p>

            <h2>Your Choices:</h2>
            <h3>6.1 Access and Correction:</h3>
            <p>You can access, update, or correct your personal information by opening a support ticket on the dashboard.</p>

            <h2>Changes to this Policy:</h2>
            <h3>7.1 Updates:</h3>
            <p>We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a notice on our website or by sending you an email.</p>

            <h2>Contact Us:</h2>
            <h3>8.1 Questions:</h3>
            <p>If you have any questions, concerns, or requests related to your privacy or this Privacy Policy, please contact us at <b>contact@durbun.net</b>.</p>

            <p>By using DURBUN.net, you acknowledge and consent to the practices outlined in this Privacy Policy.</p>
        </div>
    )
}