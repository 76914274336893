import { convert } from 'html-to-text';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { publish } from "../../../helpers/publish";

import {
    BsChevronLeft,
    BsDownload
} from "react-icons/bs";
import { FaTrashCan } from "react-icons/fa6";
import { GrWordpress } from "react-icons/gr";

import ConfirmationModal from "../../../components/confirmation-modal";
import DownloadModal from "../../../components/download-modal";
import CustomEditor from "../../../components/editor";
import PlainEditor from "../../../components/plain";
import PublishModal from "../../../components/publish-modal";

import './articles.scss';

const articleTypes = ["article", "enhanced-article", "review", "story", "comparison", "article-2"];

export default function Article({ ID: id, refetch }) {

    /** STATES **/
    const [loading, setLoading] = useState(false);
    const [publishing, setPublishing] = useState(false);

    // Article-related
    const [image, setImage] = useState(null);
    const [content, setContent] = useState("");
    const [article, setArticle] = useState(null);
    const [plainContent, setPlainContent] = useState("");
    const [renderType, setRenderType] = useState("editor");

    // Modals
    const [deleteModal, setDeleteModal] = useState(false);
    const [publishModal, setPublishModal] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);

    /** USE-EFFECTS **/
    useEffect(() => {
        if (id) {
            setLoading(true);

            fetch(process.env.REACT_APP_API + "/article/get-article?id=" + id, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'access-token': localStorage.getItem("login_token")
                }
            })
            .then(data => data.json())
            .then(data => {
                setLoading(false);

                if (data?.article) {
                    setArticle(data.article);
                    setImage(data.article.image);
                    setContent(data.article.content);
                    setPlainContent(convert(data.article.content));
                    setRenderType(
                        (
                            data.article.type === "enhanced-article" ||
                            data.article.type === "review" ||
                            data.article.type === "comparison" ||
                            data.article.type === "article-2"
                        )
                            ? "plain"
                            : "editor"
                    );
                }
                else if (!data?.success && data?.message) toast.error(data.message);
            });
        }
        else if (article) setArticle(null);
    }, [id]);

    // Download articles
    function download(type, ext) {
        fetch(process.env.REACT_APP_API + "/article/get-contents", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                singleID: id
            })
        })
        .then(data => data.json())
        .then(data => {
            if (!data?.article) toast.error("Download is failed");

            let a = data.article;

            // File Name
            let filename = a.content.slice(0, 15) + "...";
                    
            // File Content
            const htmlStyling = 
            `<style>`+
                `body { padding: 10px; }`+
                `* { font-family: sans-serif; margin: 0; }`+
                `h1, h2, h3, h4, h5, h6 { margin-top: 15px; }`+
                `p { margin: 5px 0 10px 10px; }`+
                `img { width: 60%; }`+
            `</style>`.replaceAll(" ", "", "\n", "");

            let source;
            if (type === "txt")
                source = 'data:text/plain;charset=utf-8,' + encodeURIComponent(convert(a.content));

            else if (type === "html") {
                source = 'data:text/html;charset=utf-8,' + encodeURIComponent(
                    (a?.image ? `<img src="${a.image}" />` : "") + 
                    htmlStyling + 
                    a.content.replaceAll("<br>", "")
                );
            }

            // Download File
            let fileDownload = document.createElement("a");
            document.body.appendChild(fileDownload);
            fileDownload.href = source;
            fileDownload.download = filename + "." + type;
            fileDownload.click();
            document.body.removeChild(fileDownload);
        });
    }

    // Save articles
    function save() {
        if (!plainContent?.trim()) {
            toast.error("Your article must contain content");
            return;
        }

        setLoading(true);

        fetch(process.env.REACT_APP_API + "/article/save", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                content,
                article_id: id
            })
        })
        .then(data => data.json())
        .then(data => {
            setLoading(false);

            if (data?.success)
                toast.success("Article is successfully saved");
            else if (data?.message)
                toast.error(data.message);
        });
    }

    // Delete Article
    function deleteArticle() {
        fetch(process.env.REACT_APP_API + "/article/delete-articles", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
                'access-token': localStorage.getItem("login_token")
            },
            body: JSON.stringify({
                singleID: id
            })
        })
        .then(data => data.json())
        .then(data => {
            setDeleteModal(false);

            if (data?.success) {
                toast.success("Selected articles successfully deleted");
                refetch();
            }

            else if (data?.message) toast.error(data.message);
        })
        .catch(err => {
            console.error(err);
            setDeleteModal(false);
        });
    }

    return (
        <div id="articles">
            <div id="article">
                { renderType === "editor" ? (
                    <CustomEditor
                        loading={loading}
                        content={content}
                        setContent={setContent}
                        setPlainContent={setPlainContent}
                        image={image}
                        save={save}
                    />
                ) : (
                    <PlainEditor
                        content={content}
                        image={image}
                    />
                )}
            </div>
            <div className="bottom">
                <Link to="/dashboard/articles">
                    <BsChevronLeft /><span>Go back</span>
                </Link>
                <div>
                    {/* Delete Button */}
                    <button
                        onClick={() => setDeleteModal(true)}
                        className="red"
                    >
                        <FaTrashCan />
                        <span>Delete</span>
                    </button>

                    {/* Publish on WordPress Button */}
                    <button
                        disabled={!articleTypes.includes(article?.type) || article?.published}
                        onClick={() => setPublishModal(true)}
                        className="blue"
                    >
                        <GrWordpress />
                        <span>Publish on WordPress Blog</span>
                    </button>

                    {/* Download Button */}
                    <button
                        onClick={() => setDownloadModal(true)}
                    >
                        <BsDownload />
                        <span>Download</span>
                    </button>

                    {publishing && <img src="/images/loader.svg" className="loader" alt="Loader" />}
                </div>
            </div>

            {/* Modals */}
            {deleteModal &&
                <ConfirmationModal
                    body={`You're going to delete 1 article, are you sure?`}
                    set={setDeleteModal}
                    click={deleteArticle}
                />
            }

            {publishModal &&
                <PublishModal
                    publishFunc={({ status, url, category = undefined }) => {
                        setPublishing(true);

                        let params = {
                            url,
                            status,
                            selected: { $match: { id } }
                        };

                        if (category !== undefined)
                            params["category"] = category;

                        publish(params)
                            .then(resp => {
                                setPublishing(false);

                                if (!resp?.multiple)
                                    toast.success("Article is successfully published");
                            })
                            .catch(err => {
                                setPublishing(false);
                                toast.error(err);
                            });
                    }}
                    set={setPublishModal}
                    length={1}
                />
            }

            {downloadModal &&
                <DownloadModal
                    click={type => download(type)}
                    set={setDownloadModal}
                    ext={"file"}
                />
            }
        </div>
    )
}