import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Brand from '../brand';
import './footer.scss';

export default function Footer() {
    return (
        <footer>
            <div className='top'>
                <div className='about'>
                    <div className='image'>
                        <img src='/images/logo.svg' />
                    </div>
                    <div className="text">
                        <Brand />
                        <p>DURBUN.net is a web-based AI article-generation service that doesn't require any API key or subscription.</p>
                        <br />
                        <p>E-Mail: <b>contact@durbun.net</b></p>
                        <p>Contact our <Link to="/dashboard/tickets">Support Team</Link></p>
                    </div>
                </div>
                <div className='pages'>
                    <h6>Pages</h6>
                    <div>
                        <ul>
                            <li><Link to="/tos">Terms of Usage</Link></li>
                            <li><Link to="/privacy">Privacy Policy</Link></li>
                            <li><HashLink to="/#contact">Contact Us</HashLink></li>
                            <li><Link to="/how-to-use">How to Use?</Link></li>
                            <li><Link to="/faq">FAQ</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/pricing">Pricing</Link></li>
                            <li><Link to="/login">Login</Link></li>
                            <li><Link to="/dashboard">Dashboard</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/dashboard/templates/article">Generate Article</Link></li>
                            <li><Link to="/dashboard/templates/faq">Generate FAQ</Link></li>
                            <li><Link to="/dashboard/templates/spinner">Spinner</Link></li>
                            <li><Link to="/dashboard/templates/title">Title Generator</Link></li>
                        </ul>
                    </div>
                </div>
                <div className='tags'>
                    <span>AI article generator</span>
                    <span>AI articles</span>
                    <span>Article generator</span>
                    <span>WordPress AI article generator</span>
                    <span>Unique article generator</span>
                    <span>AI generated articles</span>
                    <span>AI written articles</span>
                    <span>ChatGPT articles</span>
                </div>
            </div>
            <div className='bottom'>
                <p>DURBUN.net © {new Date().getFullYear()} - All rights reserved</p>
            </div>
        </footer>
    )
}