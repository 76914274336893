import { useState, useRef, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiMenu, FiX } from "react-icons/fi";
import { useOutsideClick } from '../../hooks/useOutsideClick';
import Brand from '../brand';

import './header.scss';

export default function Header() {

    const wrapperRef = useRef(null);
    const user = useSelector(state => state.user);

    useOutsideClick(wrapperRef, () => setHamburger(false));

    const [hamburger, setHamburger] = useState(false);
    const [announcement, setAnnouncement] = useState(true);
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        setAnnouncement(!localStorage.getItem("announcement-showed"));
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    return (
        <>
        { announcement && (
            <div
                id="announcement"
                className={(announcement && scrollPosition <= 50) ? "page-start" : undefined}
            >
                <div>
                    <p>Sign up now and get 5000 words for FREE 🔥</p>
                    <FiX onClick={() => {
                        setAnnouncement(false);
                        localStorage.setItem("announcement-showed", true);
                    }} />
                </div>
            </div>
        )}

        <header
            className={(announcement && scrollPosition <= 50) ? "page-start" : undefined}
        >
            <h1 className="title">
                <Link to="/"><Brand /></Link>
            </h1>

            <div className="links">
                <Link to="/pricing">Pricing</Link>
                <Link to="/how-to-use">How to Use?</Link>
                <HashLink to="/#contact">Contact Us</HashLink>
                <Link to="/faq">FAQ</Link>

                {user ? (
                    <Link to="/dashboard" className="login">Dashboard</Link>
                ) : (
                    <Link to="/login" className="login">Login</Link>
                )}
            </div>

            <div
                className="hamburger"
                onClick={() => {
                    setHamburger(!hamburger);
                }}
            >
                <FiMenu color="#000" size={40} />
            </div>

            <div className={"mobile-menu " + (hamburger ? "toggle" : "")} ref={wrapperRef}>
                <Link onClick={() => setHamburger(false)} to="/pricing">Pricing</Link>
                <Link onClick={() => setHamburger(false)} to="/how-to-use">How to Use?</Link>
                <HashLink onClick={() => setHamburger(false)} to="/#contact">Contact Us</HashLink>
                <Link onClick={() => setHamburger(false)} to="/faq">FAQ</Link>

                {user ? (
                    <Link onClick={() => setHamburger(false)} to="/dashboard" className="login">Dashboard</Link>
                ) : (
                    <Link to="/login" className="login">Login</Link>
                )}
            </div>
        </header>
        </>
    )
}